import React, { useState } from "react";
import { Table, Button, Popconfirm, Tag, Select } from "antd";
import {
  ServiceOrder,
  ServiceOrderStatus,
} from "../../types/serviceOrder.type";

const { Option } = Select;

type OrdersTableProps = {
  orders: ServiceOrder[];
  userRole: (order: ServiceOrder) => "owner" | "customer" | null;
  onStatusChange: (orderId: string, newStatus: ServiceOrderStatus) => void;
  onCancelOrder: (orderId: string) => void;
};

const OrdersTable: React.FC<OrdersTableProps> = ({
  orders,
  userRole,
  onStatusChange,
  onCancelOrder,
}) => {
  const [filterRole, setFilterRole] = useState<"all" | "owner" | "customer">(
    "all"
  );

  const handleStatusChange = (
    orderId: string,
    newStatus: ServiceOrderStatus
  ) => {
    onStatusChange(orderId, newStatus);
  };

  const handleOrderCancel = async (orderId: string) => {
    await onCancelOrder(orderId);
  };

  const statusFilters = [
    { text: "Ожидание", value: ServiceOrderStatus.Pending },
    { text: "В процессе", value: ServiceOrderStatus.InProgress },
    { text: "Завершено", value: ServiceOrderStatus.Completed },
    { text: "Отменено", value: ServiceOrderStatus.Cancelled },
  ];

  const columns = [
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Цена",
      dataIndex: "price",
      key: "price",
      sorter: (a: ServiceOrder, b: ServiceOrder) =>
        parseFloat(a.price) - parseFloat(b.price),
      render: (text: number) => `${text.toFixed(2)}₽`,
    },
    {
      title: "Город",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Владелец",
      dataIndex: "ownerName",
      key: "ownerName",
    },
    {
      title: "Телефон владельца",
      dataIndex: "ownerPhone",
      key: "ownerPhone",
    },
    {
      title: "Клиент",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Телефон клиента",
      dataIndex: "customerPhone",
      key: "customerPhone",
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      filters: statusFilters,
      onFilter: (value: string | number, record: ServiceOrder) =>
        record.status === value,
      render: (text: ServiceOrderStatus, record: ServiceOrder) => {
        const role = userRole(record);
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tag color={getStatusColor(text)}>{text}</Tag>
            {role === "owner" && (
              <>
                {text === ServiceOrderStatus.Pending && (
                  <Popconfirm
                    title="Вы уверены, что хотите начать выполнение этого заказа?"
                    onConfirm={() =>
                      handleStatusChange(
                        record._id,
                        ServiceOrderStatus.InProgress
                      )
                    }
                  >
                    <Button type="link" style={{ marginRight: "4px" }}>
                      Начать
                    </Button>
                  </Popconfirm>
                )}
                {text === ServiceOrderStatus.InProgress && (
                  <Popconfirm
                    title="Вы уверены, что хотите завершить выполнение этого заказа?"
                    onConfirm={() =>
                      handleStatusChange(
                        record._id,
                        ServiceOrderStatus.Completed
                      )
                    }
                  >
                    <Button type="link" style={{ marginRight: "4px" }}>
                      Завершить
                    </Button>
                  </Popconfirm>
                )}
                {(text === ServiceOrderStatus.Pending ||
                  text === ServiceOrderStatus.InProgress) && (
                  <Popconfirm
                    title="Вы уверены, что хотите отменить этот заказ?"
                    onConfirm={() => handleOrderCancel(record._id)}
                  >
                    <Button type="link" danger style={{ marginLeft: "4px" }}>
                      Отменить
                    </Button>
                  </Popconfirm>
                )}
              </>
            )}
            {role === "customer" && text === ServiceOrderStatus.Pending && (
              <Popconfirm
                title="Вы уверены, что хотите отменить этот заказ?"
                onConfirm={() => handleOrderCancel(record._id)}
              >
                <Button type="link" danger>
                  Отменить
                </Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];

  const getStatusColor = (status: ServiceOrderStatus) => {
    switch (status) {
      case ServiceOrderStatus.Pending:
        return "orange";
      case ServiceOrderStatus.InProgress:
        return "blue";
      case ServiceOrderStatus.Completed:
        return "green";
      case ServiceOrderStatus.Cancelled:
        return "red";
      default:
        return "default";
    }
  };

  const filteredOrders = orders.filter((order) => {
    const role = userRole(order);
    if (filterRole === "all") return true;
    if (filterRole === "owner" && role === "owner") return true;
    if (filterRole === "customer" && role === "customer") return true;
    return false;
  });

  return (
    <div>
      <Select
        defaultValue="all"
        style={{ marginBottom: 16, width: 200 }}
        onChange={(value) =>
          setFilterRole(value as "all" | "owner" | "customer")
        }
      >
        <Option value="all">Все заказы</Option>
        <Option value="owner">Мои заказы (Продавец)</Option>
        <Option value="customer">Мои заказы (Покупатель)</Option>
      </Select>
      <Table
        dataSource={filteredOrders}
        // @ts-ignore
        columns={columns}
        rowKey={(record) => record._id}
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
};

export default OrdersTable;
