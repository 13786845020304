import { Address } from "./address.type";
export enum SellerStatus {
  ACTIVE = "активен",
  INACTIVE = "неактивен",
}
export type Worker = {
  _id: string;
  name: string;
  phone: string;
  password: string;
  sellerId: string;
  sellerName: string;
  address: Address;
  category: string;
  schedule: string;
  photoUri: string;
  email: string;
  sellerPhone: string;
  canAddProducts: boolean;
  canDeleteProducts: boolean;
  canModifyProducts: boolean;
  status: SellerStatus;
};
export type DishonestWorker = {
  _id: string;
  name: string;
  surname: string;
  phone: string;
  seller: string;
  sellerName: string;
  sellerPhoto: string;
  reason: string;
};
