import React, { useRef, useState } from "react";
import {
  Typography,
  Spin,
  Button,
  Space,
  Input,
  Select,
  message,
  Table,
  Tag,
  Image,
  Menu,
  Dropdown,
  DatePicker,
  Modal,
} from "antd";
import { PlusCircleOutlined, DownOutlined } from "@ant-design/icons";
import { categories } from "../../constants/categories.constant";
import NewProductModal from "../../components/NewProduct/newProduct.component";
import EditProductModal from "../../components/EditProduct/editProduct.component";
import { useProduct } from "../../hooks/useProduct";
import { IOption, Product } from "../../types/product.type";
import { getCategoryColor } from "../../utils/getTagColor";
import { API } from "../../config/config";

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Search } = Input;

export default function Products() {
  const {
    products,
    loading,
    newProduct,
    deleteProduct,
    updateProduct,
    importProductsFromJson,
    importing,
    currentPage,
    setCurrentPage,
    totalCount,
    setSearch,
    setDateRange,
    limit,
    setLimit,
  } = useProduct();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleEditProduct = (productId: string) => {
    const productToEdit = products.find((product) => product._id === productId);
    if (productToEdit) {
      setSelectedProduct(productToEdit);
      setIsEditModalVisible(true);
    } else {
      message.error("Не удалось найти товар для редактирования.");
    }
  };

  const handleDeleteProduct = (productId: string) => {
    Modal.confirm({
      title: "Вы уверены, что хотите удалить этот товар?",
      content: "Это действие нельзя отменить.",
      okText: "Да, удалить",
      cancelText: "Отмена",
      onOk: () => {
        deleteProduct(productId);
      },
    });
  };

  const handleImportJson = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      await importProductsFromJson(file);
    }
  };

  const columns = [
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Категория",
      dataIndex: "category",
      key: "category",
      render: (category: number) => {
        const categoryObj = category
          ? categories.find((cat) => cat.id.toString() === category.toString())
          : null;

        if (categoryObj) {
          const tagColor = getCategoryColor(categoryObj.id);
          return <Tag color={tagColor}>{categoryObj.name}</Tag>;
        }
      },
    },
    {
      title: "Описание",
      dataIndex: "description",
      key: "description",
      width: 400,
      ellipsis: {
        showTitle: true,
      },
      render: (text: string) => (
        <div
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 4,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "normal",
          }}
        >
          {text || "Без описания"}
        </div>
      ),
    },
    {
      title: "Вес (кг)",
      dataIndex: "weight",
      key: "weight",
      render: (weight: number) => <span>{`${weight / 1000} кг`}</span>,
    },
    {
      title: "Фото товара",
      dataIndex: "photos",
      key: "photos",
      render: (photos: string[]) => (
        <div>
          {photos && photos.length > 0 ? (
            photos.map((photo, index) => (
              <Image
                key={index}
                src={`${API}/${photo}`}
                width={50}
                preview={{ src: `${API}/${photo}` }}
                alt="Фото"
                loading="lazy"
                style={{
                  cursor: "pointer",
                  borderRadius: 4,
                  border: "1px solid #ddd",
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                }}
              />
            ))
          ) : (
            <span>Нет фото</span>
          )}
        </div>
      ),
    },
    {
      title: "Раздел",
      dataIndex: "chapterName",
      key: "chapterName",
      render: (chapterName: string) => <span>{chapterName}</span>,
    },
    {
      title: "Опции",
      dataIndex: "options",
      key: "options",
      render: (options: IOption[]) => (
        <div>
          {options.map((option) => (
            <div key={option.choice} style={{ marginBottom: 5 }}>
              <Tag color="green">
                {option.title}: {option.choice} - {option.price}₽
              </Tag>
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "Дата",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "",
      key: "actions",
      render: (_: any, product: Product) => {
        const menu = (
          <Menu>
            <Menu.Item onClick={() => handleEditProduct(product._id)}>
              Изменить
            </Menu.Item>
            <Menu.Item onClick={() => handleDeleteProduct(product._id)}>
              Удалить
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu}>
            <Button type="link">
              Действия <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const handleSearchName = (value: string) => {
    setSearch(value);
    setCurrentPage(1);
  };

  return (
    <Spin spinning={importing}>
      <div style={{ padding: "16px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 24,
          }}
        >
          <Title level={2}>Товары</Title>
          <div>
            <Button
              style={{ marginRight: 12 }}
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => setIsModalVisible(true)}
            >
              Новый
            </Button>

            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={onFileChange}
              accept=".json"
            />
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={handleImportJson}
              disabled={importing}
            >
              Импортировать JSON
            </Button>
          </div>
        </div>
        <Space
          direction="horizontal"
          style={{ marginBottom: 24, flexWrap: "wrap" }}
          align="center"
        >
          <Search
            placeholder="Поиск по имени"
            onSearch={handleSearchName}
            allowClear
          />
          <RangePicker
            onChange={(dates) => setDateRange(dates ?? undefined)}
            placeholder={["Начало", "Конец"]}
            format={{ format: "DD.MM.YY" }}
          />
        </Space>
        <Table
          columns={columns}
          dataSource={products}
          rowKey={(record) => record._id}
          pagination={{
            current: currentPage,
            total: totalCount,
            pageSize: limit,
            onChange: (page, size) => {
              setCurrentPage(page);
              if (size !== limit) setLimit(size!);
            },
          }}
          scroll={{ x: "max-content" }}
          loading={loading}
        />
        <NewProductModal
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          onAddProduct={newProduct}
        />
        {selectedProduct && (
          <EditProductModal
            visible={isEditModalVisible}
            onClose={() => setIsEditModalVisible(false)}
            product={selectedProduct}
            onUpdateProduct={updateProduct}
          />
        )}
      </div>
    </Spin>
  );
}
