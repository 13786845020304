import React, { useState } from "react";
import { Card, Button, Typography, Collapse, Modal, Image } from "antd";
import { WholesaleProduct } from "../../types/wholesale.type";
import { API } from "../../config/config";
import EditWholesale from "../EditWholesale/editWholesale.component";

const { Title, Text } = Typography;
const { Panel } = Collapse;

interface WholesaleCardProps {
  wholesale: WholesaleProduct;
  isMyWholesale?: boolean;
  onBuyWholesale?: (productId: string) => void;
  onDeleteWholesale?: (productId: string) => void;
  onUpdateWholesale?: (productId: string, productData: any) => void;
}

const WholesaleCard: React.FC<WholesaleCardProps> = ({
  wholesale,
  isMyWholesale = false,
  onBuyWholesale,
  onDeleteWholesale,
  onUpdateWholesale,
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);

  const handleOpenModal = () => setModalVisible(true);

  const handleUpdateWholesale = async (
    productId: string,
    productData: FormData
  ) => {
    if (onUpdateWholesale) {
      await onUpdateWholesale(productId, productData);
    }
  };

  const handleBuyWholesale = async () => {
    if (onBuyWholesale) {
      setLoading(true);
      await onBuyWholesale(wholesale._id);
      setLoading(false);
      setVisible(false);
    }
  };

  const handleDeleteWholesale = async () => {
    if (onDeleteWholesale) {
      setDeleting(true);
      await onDeleteWholesale(wholesale._id);
      setDeleting(false);
    }
  };

  return (
    <>
      <Card
        cover={
          <Image
            alt={wholesale.title}
            src={`${API}/${wholesale.photoUri}`}
            style={{
              width: "100%",
              height: 180,
              objectFit: "cover",
            }}
          />
        }
        style={{
          width: 300,
          borderRadius: 8,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
        actions={[
          ...(isMyWholesale
            ? [
                <Button type="default" key="edit" onClick={handleOpenModal}>
                  Изменить
                </Button>,
                <Button
                  type="default"
                  key="delete"
                  loading={deleting}
                  onClick={() =>
                    Modal.confirm({
                      title: "Подтверждение удаления",
                      content: "Вы уверены, что хотите удалить этот продукт?",
                      okText: "Да",
                      cancelText: "Нет",
                      onOk: handleDeleteWholesale,
                    })
                  }
                >
                  Удалить
                </Button>,
              ]
            : [
                <Button
                  type="primary"
                  key="action"
                  onClick={() => setVisible(true)}
                >
                  Купить
                </Button>,
              ]),
        ]}
      >
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: 16 }}
        >
          <div>
            <Title level={4} style={{ margin: 0 }}>
              {wholesale.ownerName}
            </Title>
          </div>
        </div>
        <Title level={5} style={{ margin: "8px 0" }}>
          {wholesale.title}
        </Title>
        <div style={{ marginBottom: 16 }}>
          <Text strong>Город:</Text> {wholesale.city}
        </div>
        <div style={{ marginBottom: 16 }}>
          <Text strong>Цена за:</Text> {wholesale.priceFor}
        </div>
        <Collapse
          bordered={false}
          expandIconPosition="right"
          activeKey={collapsed ? [] : ["1"]}
          onChange={() => setCollapsed(!collapsed)}
          style={{ marginBottom: 16 }}
        >
          <Panel header="Описание" key="1">
            <Text>{wholesale.description}</Text>
          </Panel>
        </Collapse>
        <div style={{ marginTop: 16 }}>
          <Title level={5} style={{ margin: 0 }}>
            Цена: {wholesale.price}₽
          </Title>
        </div>
      </Card>
      <Modal
        title="Подтверждение"
        visible={visible}
        onOk={handleBuyWholesale}
        onCancel={() => setVisible(false)}
        okText="Да"
        cancelText="Нет"
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Отмена
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleBuyWholesale}
          >
            {loading ? "Покупка..." : "Купить"}
          </Button>,
        ]}
      >
        <p>Вы уверены, что хотите купить этот продукт?</p>
      </Modal>
      <EditWholesale
        visible={isModalVisible}
        onClose={() => setModalVisible(false)}
        wholesale={wholesale}
        onUpdateWholesale={handleUpdateWholesale}
      />
    </>
  );
};

export default WholesaleCard;
