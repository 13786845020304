import React from "react";
import { Menu } from "antd";
import { items } from "../../constants/items.constant";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext/auth.context";

export default function Sidebar({ darkTheme }: { darkTheme: boolean }) {
  const navigate = useNavigate();
  const location = useLocation();

  const currentPath = location.pathname;

  const { authState } = useAuth();

  const filteredItems =
    authState?.userType === "worker"
      ? items.filter((item) => item.key !== "/finance")
      : items;

  return (
    <Menu
      onClick={(item) => {
        navigate(item.key);
      }}
      selectedKeys={[currentPath]}
      items={filteredItems}
      theme={darkTheme ? "dark" : "light"}
      mode="inline"
      style={{
        fontSize: "1rem",
        marginTop: 80,
      }}
    />
  );
}
