import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext/auth.context";
import {
  WholesaleOrder,
  WholesaleOrderStatus,
  WholesaleProduct,
} from "../types/wholesale.type";
import { message } from "antd";
import $api from "../api/api";

const useWholesale = () => {
  const { authState } = useAuth();
  const [products, setProducts] = useState<WholesaleProduct[]>([]);
  const [myProducts, setMyProducts] = useState<WholesaleProduct[]>([]);
  const [orders, setOrders] = useState<WholesaleOrder[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortCriteria, setSortCriteria] = useState<"title" | "price" | "date">(
    "title"
  );
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await $api.get(`/wholesale?userType=${authState?.userType}`);
      setProducts(response.data.wholesaleProducts);
    } catch (error) {
      console.error("Произошла ошибка при загрузке товаров.");
    } finally {
      setLoading(false);
    }
  };

  const fetchMyProducts = async () => {
    try {
      setLoading(true);
      const response = await $api.get(
        `/wholesale/my?userType=${authState?.userType}`
      );
      setMyProducts(response.data.wholesaleProducts);
    } catch (error) {
      console.error("Произошла ошибка при загрузке ваших товаров.");
    } finally {
      setLoading(false);
    }
  };

  const fetchOrders = async () => {
    try {
      const response = await $api.get(
        `/wholesale/my-orders?userType=${authState?.userType}`
      );
      setOrders(response.data.orders);
    } catch (error) {
      console.error("Произошла ошибка при загрузке заказов.");
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchMyProducts();
    fetchOrders();
  }, [authState?.userType]);

  const createProduct = async (productData: FormData) => {
    try {
      const response = await $api.post(
        `/wholesale/new?userType=${authState?.userType}`,
        productData
      );
      message.success("Товар успешно создан.");
      setProducts((prev) => [...prev, response.data.wholesaleProduct]);
      setMyProducts((prev) => [...prev, response.data.wholesaleProduct]);
    } catch (error) {
      message.error("Произошла ошибка при создании товара.");
    }
  };

  const buyWholesale = async (productId: string) => {
    try {
      await $api.post(
        `/wholesale/new-order/${productId}?userType=${authState?.userType}`
      );
      message.success("Товар успешно куплен. Смотрите детали в Мои Заказы.");
    } catch (error: any) {
      message.error(error.response.data.error);
    }
  };

  const deleteWholesale = async (productId: string) => {
    try {
      await $api.delete(
        `/wholesale/delete/${productId}?userType=${authState?.userType}`
      );
      message.success("Товар успешно удален.");
      setMyProducts((prev) =>
        prev.filter((product) => product._id !== productId)
      );
      setProducts((prev) =>
        prev.filter((product) => product._id !== productId)
      );
    } catch (error) {
      console.error(error);
      message.error("Произошла ошибка при удалении товара.");
    }
  };

  const updateWholesale = async (productId: string, productData: FormData) => {
    try {
      const response = await $api.put(
        `/wholesale/edit/${productId}?userType=${authState?.userType}`,
        productData
      );
      message.success("Товар успешно изменен.");
      setMyProducts((prev) =>
        prev.map((product) =>
          product._id === productId ? response.data.product : product
        )
      );
      setProducts((prev) =>
        prev.map((product) =>
          product._id === productId ? response.data.product : product
        )
      );
    } catch (error) {
      console.error(error);
      message.error("Произошла ошибка при изменении товара.");
    }
  };

  const cancelOrder = async (orderId: string) => {
    try {
      await $api.post(
        `/wholesale/cancel/${orderId}?userType=${authState?.userType}`
      );
      message.success("Заказ успешно отменен.");
      setOrders((prev) =>
        prev.map((order) =>
          order._id === orderId
            ? { ...order, status: WholesaleOrderStatus.Cancelled }
            : order
        )
      );
    } catch (error) {
      message.error("Произошла ошибка при отмене заказа.");
    }
  };

  const changeOrderStatus = async (
    orderId: string,
    newStatus: WholesaleOrderStatus
  ) => {
    try {
      await $api.put(
        `/wholesale/status/${orderId}?userType=${authState?.userType}`,
        {
          status: newStatus,
        }
      );
      message.success("Статус заказа успешно изменен.");
      setOrders((prev) =>
        prev.map((order) =>
          order._id === orderId ? { ...order, status: newStatus } : order
        )
      );
    } catch (error) {
      message.error("Произошла ошибка при изменении статуса заказа.");
    }
  };

  const filteredProducts = products.filter(
    (product) =>
      product.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedProducts = filteredProducts.sort((a, b) => {
    let comparison = 0;
    if (sortCriteria === "title") {
      comparison = a.title.localeCompare(b.title);
    } else if (sortCriteria === "price") {
      comparison = a.price - b.price;
    } else if (sortCriteria === "date") {
      comparison =
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    }
    return sortOrder === "asc" ? comparison : -comparison;
  });

  return {
    products: sortedProducts,
    myProducts,
    orders,
    loading,
    createProduct,
    buyWholesale,
    deleteWholesale,
    updateWholesale,
    cancelOrder,
    changeOrderStatus,
    setSearchTerm,
    setSortCriteria,
    setSortOrder,
  };
};

export default useWholesale;
