import { useAuth } from "../../contexts/AuthContext/auth.context";
import { Avatar, Typography, Menu, Space } from "antd";
import { API } from "../../config/config";
import {
  UserOutlined,
  ShopOutlined,
  PhoneOutlined,
  SyncOutlined,
} from "@ant-design/icons";

const { Text, Title } = Typography;

export default function Header({ darkTheme }: { darkTheme: boolean }) {
  const { authState } = useAuth();
  const userType = authState?.userType;
  const name = authState?.name;
  const photoUri = authState?.photoUri;
  const phone = authState?.phone;
  const sellerName = authState?.sellerName;
  const status = authState?.status;
  const address = authState?.address;
  const balanceAmount = authState?.balance?.amount;

  const userMenu = (
    <Menu>
      <Menu.Item key="1">
        <PhoneOutlined style={{ marginRight: 8 }} />
        {phone || "Не указан"}
      </Menu.Item>
      {userType === "worker" && (
        <Menu.Item key="2">
          <ShopOutlined style={{ marginRight: 8 }} />
          {sellerName || "Не указан"}
        </Menu.Item>
      )}
      <Menu.Item key="3">
        <SyncOutlined style={{ marginRight: 8 }} />
        {status || "Не указан"}
      </Menu.Item>
    </Menu>
  );

  return (
    <header
      style={{
        borderRadius: 12,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px 24px",
        backgroundColor: darkTheme ? "#001529" : "#fff",
        color: darkTheme ? "#fff" : "#000",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        position: "sticky",
        top: 16,
        zIndex: 1,
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Avatar
          src={photoUri ? `${API}/${photoUri}` : undefined}
          size={48}
          style={{
            backgroundColor: darkTheme ? "#1890ff" : "#f0f0f0",
            marginRight: 16,
          }}
          icon={<UserOutlined />}
        />
        <div>
          <Text
            strong
            style={{ fontSize: 18, color: darkTheme ? "#fff" : "#000" }}
          >
            {name || "Имя не указано"}
          </Text>
          <Text
            type="secondary"
            style={{ color: darkTheme ? "#ccc" : "#888", display: "block" }}
          >
            {address
              ? `${address.city}, ${address.street}, дом ${address.house} ,вход ${address.entrance} кв.${address.apartment}, ${address.floor} этаж`
              : "Адрес не указан"}
          </Text>
        </div>
      </div>

      <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
        {balanceAmount !== undefined && (
          <div style={{ textAlign: "right" }}>
            <Space>
              <Text
                strong
                style={{
                  fontSize: 18,
                  color: darkTheme ? "#52c41a" : "#1890ff",
                }}
              >
                {balanceAmount.toFixed(2)} ₽
              </Text>
            </Space>
          </div>
        )}
      </div>
    </header>
  );
}
