import { useState, useEffect } from "react";
import { message } from "antd";
import { Service } from "../types/service.type";
import { useAuth } from "../contexts/AuthContext/auth.context";
import { ServiceOrder, ServiceOrderStatus } from "../types/serviceOrder.type";
import $api from "../api/api";

const useService = () => {
  const { authState } = useAuth();
  const [services, setServices] = useState<Service[]>([]);
  const [myServices, setMyServices] = useState<Service[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortCriteria, setSortCriteria] = useState<"title" | "price" | "date">(
    "title"
  );
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [orders, setOrders] = useState<ServiceOrder[]>([]);

  const fetchServices = async () => {
    try {
      setLoading(true);
      const response = await $api.get(`/service?userType=${authState?.userType}`);
      setServices(response.data.services);
    } catch (error) {
      console.error("Произошла ошибка при загрузке услуг.");
    } finally {
      setLoading(false);
    }
  };

  const fetchMyServices = async () => {
    try {
      const response = await $api.get(
        `/service/my?userType=${authState?.userType}`
      );
      setMyServices(response.data.services);
    } catch (error) {
      console.error("Произошла ошибка при загрузке ваших услуг.");
    }
  };

  const fetchOrders = async () => {
    try {
      const response = await $api.get(
        `/service/my-orders?userType=${authState?.userType}`
      );
      setOrders(response.data.orders);
    } catch (error) {
      console.error("Произошла ошибка при загрузке заказов.");
    }
  };

  useEffect(() => {
    fetchServices();
    fetchMyServices();
    fetchOrders();
  }, [authState?.userType]);

  const takeService = async (serviceId: string) => {
    try {
      await $api.post(
        `/service/take/${serviceId}?userType=${authState?.userType}`
      );
      message.success("Услуга успешно получена.");
    } catch (error) {
      message.error("Произошла ошибка при получении услуги.");
    }
  };

  const deleteService = async (serviceId: string) => {
    try {
      await $api.delete(
        `/service/delete/${serviceId}?userType=${authState?.userType}`
      );
      message.success("Услуга успешно удалена.");
      setMyServices((prev) =>
        prev.filter((service) => service._id !== serviceId)
      );
      setServices((prev) =>
        prev.filter((service) => service._id !== serviceId)
      );
    } catch (error) {
      console.error(error);
      message.error("Произошла ошибка при удалении услуги.");
    }
  };

  const updateService = async (serviceId: string, serviceData: Partial<Service>) => {
    try {
      const response = await $api.put(
        `/service/edit/${serviceId}?userType=${authState?.userType}`,
        serviceData
      );
      message.success("Услуга успешно изменена.");
      setMyServices((prev) =>
        prev.map((service) =>
          service._id === serviceId ? response.data.service : service
        )
      );
      setServices((prev) =>
        prev.map((service) =>
          service._id === serviceId ? response.data.service : service
        )
      );
    } catch (error) {
      message.error("Произошла ошибка при изменении услуги.");
    }
  };

  const createService = async (serviceData: {
    title: string;
    description: string;
    price: number;
    category: number;
    priceFor: string;
  }) => {
    try {
      console.log(serviceData);

      const response = await $api.post(
        `/service/new/?userType=${authState?.userType}`,
        serviceData
      );
      message.success("Услуга успешно создана.");
      setServices((prev) => [...prev, response.data.service]);
      setMyServices((prev) => [...prev, response.data.service]);
    } catch (error: any) {
      message.error(error.response.data.error);
    }
  };

  const changeOrderStatus = async (
    orderId: string,
    newStatus: ServiceOrderStatus
  ) => {
    try {
      await $api.put(
        `/service/status/${orderId}?userType=${authState?.userType}`,
        {
          status: newStatus,
        }
      );
      message.success("Статус заказа успешно изменен.");
      setOrders((prev) =>
        prev.map((order) =>
          order._id === orderId ? { ...order, status: newStatus } : order
        )
      );
    } catch (error) {
      message.error("Произошла ошибка при изменении статуса заказа.");
    }
  };

  const cancelOrder = async (orderId: string) => {
    try {
      await $api.post(
        `/service/cancel/${orderId}?userType=${authState?.userType}`
      );
      message.success("Заказ успешно отменен.");
      setOrders((prev) =>
        prev.map((order) =>
          order._id === orderId
            ? { ...order, status: ServiceOrderStatus.Cancelled }
            : order
        )
      );
    } catch (error) {
      message.error("Произошла ошибка при отмене заказа.");
    }
  };

  const filteredServices = services.filter(
    (service) =>
      service.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      service.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedServices = filteredServices.sort((a, b) => {
    let comparison = 0;
    if (sortCriteria === "title") {
      comparison = a.title.localeCompare(b.title);
    } else if (sortCriteria === "price") {
      comparison = a.price - b.price;
    } else if (sortCriteria === "date") {
      comparison =
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    }
    return sortOrder === "asc" ? comparison : -comparison;
  });

  return {
    services: sortedServices,
    myServices,
    loading,
    takeService,
    deleteService,
    updateService,
    createService,
    setSearchTerm,
    setSortCriteria,
    setSortOrder,
    orders,
    cancelOrder,
    changeOrderStatus,
  };
};

export default useService;
