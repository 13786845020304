import React, { useEffect, useState } from "react";
import {
  UserType,
  WithdrawalRequest,
  WithdrawalStatus,
} from "../../types/withdrawalRequest.type";
import $api from "../../api/api";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useAuth } from "../../contexts/AuthContext/auth.context";

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

export default function WithdrawalRequests() {
  const { authState, setAuthState } = useAuth();
  const [requests, setRequests] = useState<WithdrawalRequest[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [dateRange, setDateRange] = useState<[any, any] | undefined>(undefined);
  const [status, setStatus] = useState<WithdrawalStatus | undefined>(undefined);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const fetchRequests = async () => {
    setLoading(true);
    try {
      const response = await $api.get("/transaction/withdrawal", {
        params: {
          page,
          status,
          limit: pageSize,
          startDate: dateRange ? dateRange[0]?.format("DD.MM.YY") : undefined,
          endDate: dateRange ? dateRange[1]?.format("DD.MM.YY") : undefined,
        },
      });
      setRequests(response.data.requests);
      setTotal(response.data.total);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, [page, pageSize, status, dateRange]);

  const columns = [
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (status: WithdrawalStatus) => {
        let statusColor;
        switch (status) {
          case WithdrawalStatus.Completed:
            statusColor = "green";
            break;
          case WithdrawalStatus.Rejected:
            statusColor = "red";
            break;
          case WithdrawalStatus.Pending:
            statusColor = "orange";
            break;
          default:
            statusColor = "gray";
        }
        return <span style={{ color: statusColor }}>{status}</span>;
      },
    },
    {
      title: "Сумма",
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => {
        return new Intl.NumberFormat("ru-RU", {
          style: "currency",
          currency: "RUB",
        }).format(amount);
      },
    },
    {
      title: "Карта",
      dataIndex: "cardNumber",
      key: "cardNumber",
      render: (cardNumber: number) => {
        const formattedCardNumber = cardNumber
          .toString()
          .replace(/\D/g, "")
          .replace(/(\d{4})(?=\d)/g, "$1 ");

        return formattedCardNumber;
      },
    },
    {
      title: "Дата",
      dataIndex: "date",
      key: "date",
    },
  ];

  const handleFormSubmit = async (values: any) => {
    setConfirmLoading(true);
    const cardNumber = values.cardNumber.replace(/\s/g, "");

    try {
      await $api.post(`/transaction/withdrawal?userType=${UserType.Seller}`, {
        ...values,
        cardNumber,
      });

      message.success("Заявка успешно создана.");
      fetchRequests();
      setIsModalVisible(false);
      setConfirmLoading(false);
      form.resetFields();

      setAuthState((prev) => ({
        ...prev,
        balance: {
          ...prev.balance,
          amount: (prev.balance?.amount || 0) - values.amount,
        },
      }));
    } catch (error: any) {
      message.error(error.response.data.error);
      setIsModalVisible(false);
      setConfirmLoading(false);
    }
  };

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const formattedValue = value
      .replace(/\D/g, "")
      .replace(/(\d{4})(?=\d)/g, "$1 ");
    form.setFieldsValue({
      cardNumber: formattedValue,
    });
  };
  return (
    <div style={{ padding: "16px" }}>
      <Title level={2}>Заявки на вывод</Title>

      <Space
        direction="horizontal"
        style={{ marginBottom: 24, flexWrap: "wrap" }}
        align="center"
      >
        <Select
          placeholder="Тип"
          style={{ width: 200 }}
          allowClear
          onChange={(value) => setStatus(value)}
        >
          {Object.values(WithdrawalStatus).map((status) => (
            <Option key={status} value={status}>
              {status}
            </Option>
          ))}
        </Select>
        <RangePicker
          onChange={(dates) => setDateRange(dates ?? undefined)}
          placeholder={["Начало", "Конец"]}
          format={{ format: "DD.MM.YY" }}
        />
      </Space>
      <Button
        style={{ marginLeft: 12 }}
        type="primary"
        icon={<PlusCircleOutlined />}
        onClick={() => setIsModalVisible(true)}
      >
        Создать
      </Button>
      <Table
        columns={columns}
        dataSource={requests}
        rowKey={(record) => record._id}
        pagination={{
          current: page,
          total: total,
          pageSize: pageSize,
          onChange: (page, size) => {
            setPage(page);
            if (size !== pageSize) setPageSize(size!);
          },
        }}
        scroll={{ x: "max-content" }}
        loading={loading}
      />

      <Modal
        title="Создать заявку"
        visible={isModalVisible}
        onOk={() => form.submit()}
        onCancel={() => setIsModalVisible(false)}
        confirmLoading={confirmLoading}
      >
        <Form form={form} onFinish={handleFormSubmit} layout="vertical">
          <Form.Item
            name="amount"
            label="Сумма"
            rules={[{ required: true, message: "Пожалуйста, введите сумму!" }]}
          >
            <InputNumber min={10} />
          </Form.Item>
          <Form.Item
            name="cardNumber"
            label="Номер карты"
            rules={[
              { required: true, message: "Пожалуйста, введите номер карты!" },
              {
                pattern: /^[0-9]{4}(?:\s[0-9]{4}){3}$/,
                message: "Пожалуйста, введите корректный номер карты!",
              },
            ]}
          >
            <Input maxLength={19} onChange={handleCardNumberChange} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
