import React, { useState } from "react";
import { Card, Image, Typography, Space, Button } from "antd";
import { DishonestWorker } from "../../types/worker.type";
import { API } from "../../config/config";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import EditDishonestWorker from "../EditDishonest/editDishonest.component";

const { Title, Text } = Typography;

type WorkerCardProps = {
  worker: DishonestWorker;
  isMy: boolean;
  onDeleteWorker?: (workerId: string) => Promise<void>;
  onUpdateWorker?: (
    workerId: string,
    workerData: Partial<DishonestWorker>
  ) => void;
};

const WorkerCard: React.FC<WorkerCardProps> = ({
  worker,
  isMy,
  onDeleteWorker,
  onUpdateWorker,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleDelete = async () => {
    if (onDeleteWorker) {
      setIsDeleting(true);
      try {
        await onDeleteWorker(worker._id);
      } catch (error) {
        console.error("Failed to delete worker:", error);
      } finally {
        setIsDeleting(false);
      }
    }
  };

  return (
    <>
      <Card
        style={{
          width: 300,
          borderRadius: 8,
          height: isMy ? 400 : 350,
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        }}
        cover={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 16,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Image
              alt="seller-logo"
              style={{ width: 50, height: 50, borderRadius: "50%" }}
              src={`${API}/${worker.sellerPhoto}`}
            />
            <Text type="secondary">{worker.sellerName}</Text>
          </div>
        }
        title={
          <Title level={4} style={{ margin: 0 }}>
            {worker.name} {worker.surname}
          </Title>
        }
        bodyStyle={{ padding: "16px" }}
      >
        <Space direction="vertical" size="small">
          <Text strong>Телефон:</Text>
          <Text>{worker.phone}</Text>
          <Text strong>Причина:</Text>
          <Text>{worker.reason}</Text>
        </Space>
        {isMy && onDeleteWorker && (
          <div
            style={{
              width: "100%",
              marginTop: 16,
              padding: 16,
              position: "absolute",
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTop: "1px solid #eee",
            }}
          >
            <Button
              type="text"
              style={{ marginRight: 40 }}
              icon={<EditOutlined />}
              onClick={() => setVisible(true)}
            />
            <Button
              type="text"
              style={{ marginLeft: 40 }}
              danger
              loading={isDeleting}
              onClick={handleDelete}
              icon={<DeleteOutlined />}
            />
          </div>
        )}
      </Card>
      {isMy && onUpdateWorker && (
        <EditDishonestWorker
          visible={visible}
          worker={worker}
          onClose={() => setVisible(false)}
          onUpdateWorker={onUpdateWorker}
        />
      )}
    </>
  );
};

export default WorkerCard;
