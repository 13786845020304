import { useState, useEffect } from 'react';
import { SUGGEST } from '../config/config';

interface ResultSuggest {
  title: { text: string };
  subtitle?: { text: string };
  uri: string;
}

interface UseSuggestionsProps {
  query: string;
  isLocality?: boolean;
}

export const useSuggestions = ({ query, isLocality = false }: UseSuggestionsProps) => {
  const [autocompleteOptions, setAutocompleteOptions] = useState<ResultSuggest[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (query.trim() === '') {
      setAutocompleteOptions([]);
      return;
    }

    const fetchAutocompleteSuggestions = async () => {
      setLoading(true);
      const typesParam = isLocality ? '&types=locality' : '';
      const apiUrl = `https://suggest-maps.yandex.ru/v1/suggest?text=${query}&apikey=${SUGGEST}&attrs=uri${typesParam}`;
      try {
        const response = await fetch(apiUrl);
        if (response.ok) {
          const data = await response.json();
          setAutocompleteOptions(data.results);
        } else {
          console.error('Error fetching autocomplete suggestions:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching autocomplete suggestions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAutocompleteSuggestions();
  }, [query, isLocality]);

  const options = autocompleteOptions.map((option) => ({
    value: isLocality ? option.title.text : `${option.title.text}${option.subtitle ? `, ${option.subtitle.text}` : ''}`,
  }));

  return { options, loading };
};
