export enum ServiceOrderStatus {
  Pending = "Ожидается",
  InProgress = "В процессе",
  Completed = "Завершено",
  Cancelled = "Отменено",
}

export type ServiceOrder = {
  _id: string;
  title: string;
  description: string;
  serviceId: string;
  price: string;
  city: string;
  owner: string;
  ownerName: string;
  ownerPhone: string;
  ownerPhoto: string;
  customerPhone: string;
  customer: string;
  customerName: string;
  status: ServiceOrderStatus;
};
