import React, { useEffect, useState } from "react";
import { Transaction, TransactionType } from "../../types/transaction.type";
import $api from "../../api/api";
import { DatePicker, Select, Space, Table, Typography } from "antd";

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

export default function Transactions() {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [dateRange, setDateRange] = useState<[any, any] | undefined>(undefined);
  const [type, setType] = useState<TransactionType | undefined>(undefined);

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const response = await $api.get("/transaction", {
        params: {
          page,
          type,
          limit: pageSize,
          startDate: dateRange ? dateRange[0]?.format("DD.MM.YY") : undefined,
          endDate: dateRange ? dateRange[1]?.format("DD.MM.YY") : undefined,
        },
      });
      setTransactions(response.data.transactions);
      setTotal(response.data.total);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [page, pageSize, type, dateRange]);

  const columns = [
    {
      title: "Тип",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Сумма",
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => {
        return new Intl.NumberFormat("ru-RU", {
          style: "currency",
          currency: "RUB",
        }).format(amount);
      },
    },
    {
      title: "Описание",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Дата",
      dataIndex: "date",
      key: "date",
    },
  ];

  return (
    <div style={{ padding: "16px" }}>
      <Title level={2}>Транзакции</Title>
      <Space
        direction="horizontal"
        style={{ marginBottom: 24, flexWrap: "wrap" }}
        align="center"
      >
        <Select
          placeholder="Тип"
          style={{ width: 200 }}
          allowClear
          onChange={(value) => setType(value)}
        >
          {Object.values(TransactionType).map((type) => (
            <Option key={type} value={type}>
              {type}
            </Option>
          ))}
        </Select>
        <RangePicker
          onChange={(dates) => setDateRange(dates ?? undefined)}
          placeholder={["Начало", "Конец"]}
          format={{ format: "DD.MM.YY" }}
        />
      </Space>
      <Table
        columns={columns}
        dataSource={transactions}
        rowKey={(record) => record._id}
        pagination={{
          current: page,
          total: total,
          pageSize: pageSize,
          onChange: (page, size) => {
            setPage(page);
            if (size !== pageSize) setPageSize(size!);
          },
        }}
        scroll={{ x: "max-content" }}
        loading={loading}
      />
    </div>
  );
}
