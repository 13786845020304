import food from "../assets/images/food.image.png";
import product from "../assets/images/product.image.png";
import zoo from "../assets/images/zoo.image.png";
import flower from "../assets/images/flower.image.png";
import brick from "../assets/images/brick.image.png";
import market from "../assets/images/market.image.png";
import pharm from "../assets/images/pharm.image.png";
import farm from "../assets/images/farm.image.png";
export const categories = [
  { id: 1, color: "#FADEED", photo: food, name: "Рестораны" },
  { id: 2, color: "#CDBCB5", photo: product, name: "Продукты" },
  { id: 3, color: "#CAE9EC", photo: pharm, name: "Аптека" },
  { id: 4, color: "#EFE0DD", photo: flower, name: "Цветы" },
  { id: 5, color: "#CAE3C3", photo: zoo, name: "Зоо товары" },
  { id: 6, color: "#A4DCDD", photo: brick, name: "Стройматериалы" },
  { id: 7, color: "#F8F2E6", photo: market, name: "Рынки" },
  { id: 8, color: "#D6B5D0", photo: farm, name: "Фермер. товары" },
];
