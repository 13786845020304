export enum DaysOfWeek {
  MONDAY = "Пн",
  TUESDAY = "Вт",
  WEDNESDAY = "Ср",
  THURSDAY = "Чт",
  FRIDAY = "Пт",
  SATURDAY = "Сб",
  SUNDAY = "Вс",
}

export interface IDaySchedule {
  dayOfWeek: DaysOfWeek;
  openTime: string;
  closeTime: string;
  isDayOff: boolean;
  is24Hours: boolean;
}

export interface ISchedule extends Array<IDaySchedule> {}
