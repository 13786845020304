import {
  Typography,
  Table,
  Tag,
  Drawer,
  Button,
  List,
  Divider,
  Row,
  Col,
  Statistic,
  Input,
  Space,
  Select,
  DatePicker,
  Menu,
  Dropdown,
  Rate,
  Image,
} from "antd";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext/auth.context";
import { Order, OrderProduct, OrderStatus } from "../../types/order.type";
import { getTagColor } from "../../utils/getTagColor";
import dayjs from "dayjs";
import { ColumnsType } from "antd/es/table";
import $api from "../../api/api";
import { DownOutlined } from "@ant-design/icons";
import useGetRequest from "../../hooks/useGetRequest";
import { Review } from "../../types/review.type";
import { API } from "../../config/config";

const { Title, Paragraph } = Typography;
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function Orders() {
  const { authState } = useAuth();
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [currentProducts, setCurrentProducts] = useState<OrderProduct[]>([]);
  const [currentOrder, setCurrentOrder] = useState<Order | null>(null);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [dateRange, setDateRange] = useState<[any, any] | undefined>(undefined);
  const [status, setStatus] = useState<OrderStatus | undefined>(undefined);
  const [trackingId, setTrackingId] = useState<string | undefined>(undefined);
  const [review, setReview] = useState<Review | null>(null);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await $api.get("/seller/my-orders", {
        params: {
          userType: authState?.userType,
          limit: pageSize,
          startDate: dateRange ? dateRange[0]?.format("DD.MM.YY") : undefined,
          endDate: dateRange ? dateRange[1]?.format("DD.MM.YY") : undefined,
          page,
          trackingId,
          status,
        },
      });
      setOrders(response.data.orders);
      setTotal(response.data.total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const fetchOrderReview = async (order: Order) => {
    const sellerId =
      authState?.userType === "seller" ? authState._id : authState?.sellerId;
    try {
      const response = await $api.get(
        `/review/one/${order._id}/${sellerId}/${order.clientId}`
      );
      if (response.data.review) {
        setReview(response.data.review);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [authState?.userType, page, pageSize, status, trackingId, dateRange]);

  const showDrawer = (products: OrderProduct[], order: Order) => {
    setCurrentProducts(products);
    setCurrentOrder(order);
    setVisible(true);
    fetchOrderReview(order);
  };

  const getTotalPrice = (products: OrderProduct[]) => {
    return products.reduce(
      (sum, product) => sum + product.price * product.quantity,
      0
    );
  };

  const getTotalWeight = (products: OrderProduct[]) => {
    return products.reduce(
      (sum, product) => sum + product.weight * product.quantity,
      0
    );
  };

  const columns = [
    {
      title: "ID отслеживания",
      dataIndex: "trackingId",
      key: "trackingId",
      ellipsis: true,
    },
    {
      title: "Дата",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Адрес клиента",
      key: "clientPoint",
      render: (text: any, record: Order) => (
        <div
          style={{
            maxWidth: 250,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <p>
            {record.clientPoint.street}, {record.clientPoint.city}
          </p>
          <p>
            {record.clientPoint.apartment}, Этаж {record.clientPoint.floor}
          </p>
        </div>
      ),
    },
    {
      title: "Телефон курьера",
      dataIndex: "courierPhone",
      key: "courierPhone",
      render: (text: any, record: Order) => record.courierPhone || "Нет данных",
    },
    {
      title: "Телефон клиента",
      dataIndex: "clientPhone",
      key: "clientPhone",
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (status: OrderStatus) => (
        <Tag color={getTagColor(status)}>{status}</Tag>
      ),
    },
    {
      title: "Общая цена",
      key: "totalPrice",
      render: (text: any, record: Order) => (
        <span>
          {getTotalPrice(
            record.sellerProducts.flatMap((sp) => sp.products)
          ).toFixed(2)}{" "}
          ₽
        </span>
      ),
    },
    {
      title: "Заплатили за доставку",
      key: "deliveryAmount",
      render: (text: any, record: Order) => {
        const totalDeliveryAmount = record.sellerProducts.reduce(
          (total, sellerProduct) => total + sellerProduct.deliveryAmount,
          0
        );
        return <span>{totalDeliveryAmount.toFixed(2)} ₽</span>;
      },
    },

    {
      title: "Общий вес",
      key: "totalWeight",
      render: (text: any, record: Order) => (
        <span>
          {(
            getTotalWeight(record.sellerProducts.flatMap((sp) => sp.products)) /
            1000
          ).toFixed(2)}{" "}
          кг
        </span>
      ),
    },
    {
      title: "",
      key: "actions",
      render: (_: any, record: Order) => {
        const menu = (
          <Menu>
            <Menu.Item
              onClick={() => {
                showDrawer(
                  record.sellerProducts.flatMap((sp) => sp.products),
                  record
                );
              }}
            >
              Подробно
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu}>
            <Button type="link">
              Действия <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ] as ColumnsType<Order>;

  return (
    <div style={{ padding: "16px" }}>
      <Title level={2} style={{ marginBottom: 24 }}>
        Заказы
      </Title>
      <Space style={{ marginBottom: 24 }}>
        <Search
          allowClear
          placeholder="Поиск по ID отслеживания"
          onSearch={(value) => setTrackingId(value)}
          style={{ width: 240 }}
        />
        <Select
          placeholder="Статус"
          style={{ width: 150 }}
          allowClear
          onChange={(value) => setStatus(value)}
        >
          {Object.values(OrderStatus).map((status) => (
            <Option key={status} value={status}>
              {status}
            </Option>
          ))}
        </Select>
        <RangePicker
          onChange={(dates) => setDateRange(dates ?? undefined)}
          placeholder={["Начало", "Конец"]}
          format={{ format: "DD.MM.YY" }}
        />
      </Space>
      <Table
        columns={columns}
        dataSource={orders}
        rowKey={(record) => record._id}
        pagination={{
          current: page,
          total: total,
          pageSize: pageSize,
          onChange: (page, size) => {
            setPage(page);
            if (size !== pageSize) setPageSize(size!);
          },
        }}
        scroll={{ x: "max-content" }}
        loading={loading}
      />
      <Drawer
        width={720}
        onClose={() => {
          setVisible(false);
          setReview(null);
        }}
        open={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {currentOrder && (
          <>
            <Title level={4}>Детали заказа</Title>
            <Paragraph>
              <strong>ID отслеживания:</strong> {currentOrder.trackingId}
            </Paragraph>
            <Paragraph>
              <strong>Дата доставки:</strong>{" "}
              {dayjs(currentOrder.deliveryDate).format("YYYY-MM-DD HH:mm:ss")}
            </Paragraph>
            <Paragraph>
              <strong>Имя клиента:</strong> {currentOrder.clientName}
            </Paragraph>
            <Paragraph>
              <strong>Телефон клиента:</strong> {currentOrder.clientPhone}
            </Paragraph>
            <Paragraph>
              <strong>Имя курьера:</strong>{" "}
              {currentOrder.courierId || "Нет данных"}
            </Paragraph>
            <Paragraph>
              <strong>Телефон курьера:</strong>{" "}
              {currentOrder.courierPhone || "Нет данных"}
            </Paragraph>
            <Divider />
            {review && (
              <div>
                <Title level={4}>Отзыв</Title>
                <div>
                  <Space size="middle">
                    <Image
                      src={`${API}/${review.clientPhoto}`}
                      alt={review.clientName}
                      width={50}
                      style={{ borderRadius: 25 }}
                    />
                    <div>
                      <div>{review.clientName}</div>
                      <div>{review.date}</div>
                    </div>
                  </Space>
                </div>
                <Title level={5}>{review.title}</Title>
                <Paragraph>{review.description}</Paragraph>
                <div>
                  <Rate disabled value={review.rating} />
                </div>
                <div>
                  {review.photos.length > 0 && (
                    <div>
                      <Title level={5}>Фотографии:</Title>
                      <div style={{ display: "flex", gap: "10px" }}>
                        {review.photos.map((photo, index) => (
                          <Image
                            key={index}
                            src={`${API}/${photo}`}
                            alt={`Фото ${index + 1}`}
                            width={100}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <Title level={4}>Товары</Title>
            <List
              itemLayout="horizontal"
              dataSource={currentProducts}
              renderItem={(product) => (
                <List.Item>
                  <List.Item.Meta
                    title={product.title}
                    description={`Опция: ${product.selectedOption} | Цена: ${product.price} ₽ | Количество: ${product.quantity}`}
                  />
                </List.Item>
              )}
            />
            <Divider />
            <Row>
              <Col span={12}>
                <Statistic
                  title="Общая цена"
                  value={getTotalPrice(currentProducts).toFixed(2)}
                  suffix="₽"
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Общий вес"
                  value={(getTotalWeight(currentProducts) / 1000).toFixed(2)}
                  suffix="кг"
                />
              </Col>
            </Row>
          </>
        )}
      </Drawer>
    </div>
  );
}
