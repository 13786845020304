import React, { useState } from "react";
import { Modal, Form, Input, Button } from "antd";
import { DishonestWorker } from "../../types/worker.type";

interface NewWorkerProps {
  visible: boolean;
  onClose: () => void;
  onCreateWorker: (workerData: Partial<DishonestWorker>) => void;
}

const NewDishonestWorker: React.FC<NewWorkerProps> = ({
  visible,
  onClose,
  onCreateWorker,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: Partial<DishonestWorker>) => {
    setLoading(true);
    try {
      await onCreateWorker(values);
      form.resetFields();
      onClose();
    } finally {
      setLoading(false);
    }
  };
  const formatPhoneNumber = (value: string) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  return (
    <Modal
      visible={visible}
      title="Создать недобросовестного сотрудника"
      onCancel={onClose}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Имя"
          name="name"
          rules={[{ required: true, message: "Пожалуйста, введите имя." }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Фамилия"
          name="surname"
          rules={[{ required: true, message: "Пожалуйста, введите фамилию." }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Телефон"
          name="phone"
          rules={[
            { required: true, message: "Пожалуйста, введите номер телефона." },
          ]}
        >
          <Input
            addonBefore="+7"
            onChange={(e) => {
              const formattedPhoneNumber = formatPhoneNumber(e.target.value);
              form.setFieldsValue({ phone: formattedPhoneNumber });
            }}
            style={{ marginBottom: 15 }}
          />
        </Form.Item>

        <Form.Item
          label="Причина"
          name="reason"
          rules={[
            {
              max: 150,
              message: "Причина не может содежать больше 150 символов.",
            },
            { required: true, message: "Пожалуйста, укажите причину." },
          ]}
        >
          <Input.TextArea rows={4} showCount={true} maxLength={300} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Создать
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewDishonestWorker;
