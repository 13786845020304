import React, { useState } from "react";
import { Modal, Form, Input, Select, Button, InputNumber } from "antd";
import { Vacancy, Schedule, SalaryPer } from "../../types/vacancy.type";

const { Option } = Select;

interface NewVacancyProps {
  visible: boolean;
  onClose: () => void;
  onCreateVacancy: (vacancyData: Partial<Vacancy>) => void;
}

const NewVacancy: React.FC<NewVacancyProps> = ({
  visible,
  onClose,
  onCreateVacancy,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleFinish = async (values: any) => {
    setLoading(true);
    console.log(values);
    const vacancyData: Partial<Vacancy> = {
      title: values.title,
      schedule: values.schedule,
      salary: values.salary,
      salaryPer: values.salaryPer,
      requirements: values.requirements,
      responsibilities: values.responsibilities,
    };

    await onCreateVacancy(vacancyData);
    form.resetFields();
    onClose();
    setLoading(false);
  };

  return (
    <Modal
      onClose={onClose}
      onCancel={onClose}
      visible={visible}
      title="Создать новую вакансию"
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={{
          schedule: Schedule.FullTime,
          salaryPer: SalaryPer.Month,
        }}
      >
        <Form.Item
          name="title"
          label="Название вакансии"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите название вакансии",
            },
          ]}
        >
          <Input placeholder="Введите название вакансии" />
        </Form.Item>

        <Form.Item
          name="schedule"
          label="График"
          rules={[{ required: true, message: "Пожалуйста, выберите график" }]}
        >
          <Select placeholder="Выберите график">
            <Option value={Schedule.FullTime}>Полный рабочий день</Option>
            <Option value={Schedule.PartTime}>Частичная занятость</Option>
            <Option value={Schedule.Flexible}>Гибкий график</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="salary"
          label="Ставка"
          rules={[{ required: true, message: "Пожалуйста, введите ставку" }]}
        >
          <InputNumber
            min={0}
            placeholder="Введите ставку"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          name="salaryPer"
          label="В"
          rules={[
            {
              required: true,
              message: "Пожалуйста, выберите частоту зарплаты",
            },
          ]}
        >
          <Select placeholder="Выберите частоту зарплаты">
            <Option value={SalaryPer.Hour}>В час</Option>
            <Option value={SalaryPer.Month}>В месяц</Option>
            <Option value={SalaryPer.Day}>В день</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="requirements"
          label="Требования"
          rules={[
            { required: true, message: "Пожалуйста, введите требования" },
            {
              max: 300,
              message: "Требования не могут содержать более 300 символов",
            },
            {
              min: 20,
              message: "Требования должны содержать не менее 20 символов",
            },
          ]}
        >
          <Input.TextArea
            rows={4}
            placeholder="Введите требования"
            showCount
            maxLength={300}
          />
        </Form.Item>

        <Form.Item
          name="responsibilities"
          label="Обязанности"
          rules={[
            { required: true, message: "Пожалуйста, введите обязанности" },
            {
              max: 300,
              message: "Обязанности не могут содержать более 300 символов",
            },
            {
              min: 20,
              message: "Обязанности должны содержать не менее 20 символов",
            },
          ]}
        >
          <Input.TextArea
            rows={4}
            placeholder="Введите обязанности"
            showCount
            maxLength={300}
          />
        </Form.Item>
      </Form>

      <div style={{ textAlign: "right" }}>
        <Button type="primary" loading={loading} onClick={() => form.submit()}>
          Создать
        </Button>
      </div>
    </Modal>
  );
};

export default NewVacancy;
