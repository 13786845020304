import { ProductOutlined } from "@ant-design/icons";
import {
  FiBriefcase,
  FiGrid,
  FiHome,
  FiMessageCircle,
  FiShoppingBag,
} from "react-icons/fi";
import { GrClearOption } from "react-icons/gr";
import { FaTruckFast } from "react-icons/fa6";
import { BiSupport } from "react-icons/bi";
import { FaRegUserCircle, FaRubleSign } from "react-icons/fa";

export const items = [
  { key: "/", icon: <FiHome />, label: "Главная" },
  { key: "/products", icon: <ProductOutlined />, label: "Товары" },
  { key: "/orders", icon: <FiShoppingBag />, label: "Заказы" },
  { key: "/services", icon: <GrClearOption />, label: "Услуги" },
  { key: "/wholesale", icon: <FaTruckFast />, label: "Снабжение" },
  { key: "/vacancies", icon: <FiBriefcase />, label: "Вакансии" },
  {
    key: "/finance",
    icon: <FaRubleSign />,
    label: "Финансы",
    children: [
      { key: "/transactions", label: "Транзакции" },
      { key: "/withdrawal-requests", label: "Заявки на вывод" },
    ],
  },
  { key: "/chapters", icon: <FiGrid />, label: "Разделы" },
  { key: "/reviews", icon: <FiMessageCircle />, label: "Отзывы" },
  { key: "/support", icon: <BiSupport />, label: "Поддержка" },
  { key: "/profile", icon: <FaRegUserCircle />, label: "Профиль" },
];
