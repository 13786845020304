import React, { useEffect, useState } from "react";
import {
  Table,
  Spin,
  Button,
  Space,
  Popconfirm,
  message,
  Modal,
  Flex,
} from "antd";
import { PlusOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import useGetRequest from "../../hooks/useGetRequest";
import { API } from "../../config/config";
import { Worker } from "../../types/worker.type";
import NewWorker from "../NewWorker/newWorker.component";
import EditWorker from "../EditWorker/editWorker.component";
import { useWorker } from "../../hooks/useWorker";

export default function Workers() {
  const { data, loading, refresh } = useGetRequest(`${API}/worker/my`);
  const [workers, setWorkers] = useState<Worker[]>([]);
  const [isNewWorkerModalVisible, setIsNewWorkerModalVisible] = useState(false);
  const [isEditWorkerModalVisible, setIsEditWorkerModalVisible] =
    useState(false);
  const [selectedWorker, setSelectedWorker] = useState<Worker | null>(null);
  const {
    createWorker,
    deleteWorker,
    updateWorker,
    loading: workerLoading,
  } = useWorker();

  useEffect(() => {
    if (data) {
      setWorkers(data.workers);
    }
  }, [data]);

  const handleDelete = async (id: string) => {
    try {
      await deleteWorker(id);
      setWorkers(workers.filter((worker) => worker._id !== id));
      message.success("Сотрудник успешно удален");
    } catch (error) {
      message.error("Ошибка при удалении сотрудника");
    }
  };

  const handleCreateWorker = async (workerData: {
    name: string;
    phone: string;
    password: string;
    canAddProducts: boolean;
    canDeleteProducts: boolean;
    canModifyProducts: boolean;
  }) => {
    try {
      const res = await createWorker(workerData);
      if (res) {
        setWorkers([...workers, res.worker]);
        message.success("Сотрудник успешно создан");
      }
    } catch (error: any) {
      message.error(error.response.data.error);
    }
  };

  const handleUpdateWorker = async (workerData: {
    name: string;
    canAddProducts: boolean;
    canDeleteProducts: boolean;
    canModifyProducts: boolean;
  }) => {
    if (selectedWorker) {
      try {
        const res = await updateWorker(selectedWorker._id, workerData);
        if (res) {
          refresh();
          message.success("Сотрудник успешно обновлен");
          setIsEditWorkerModalVisible(false);
        }
      } catch (error: any) {
        message.error(error.response.data.error);
      }
    }
  };

  const columns = [
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Добавление продуктов",
      dataIndex: "canAddProducts",
      key: "canAddProducts",
      render: (canAddProducts: boolean) =>
        canAddProducts ? (
          <CheckOutlined style={{ color: "green" }} />
        ) : (
          <CloseOutlined style={{ color: "red" }} />
        ),
    },
    {
      title: "Удаление продуктов",
      dataIndex: "canDeleteProducts",
      key: "canDeleteProducts",
      render: (canDeleteProducts: boolean) =>
        canDeleteProducts ? (
          <CheckOutlined style={{ color: "green" }} />
        ) : (
          <CloseOutlined style={{ color: "red" }} />
        ),
    },
    {
      title: "Изменение продуктов",
      dataIndex: "canModifyProducts",
      key: "canModifyProducts",
      render: (canModifyProducts: boolean) =>
        canModifyProducts ? (
          <CheckOutlined style={{ color: "green" }} />
        ) : (
          <CloseOutlined style={{ color: "red" }} />
        ),
    },
    {
      title: "Действия",
      key: "actions",
      render: (text: string, record: Worker) => (
        <Space size="middle" style={{ justifyContent: "space-between" }}>
          <Button
            type="primary"
            onClick={() => {
              setSelectedWorker(record);
              setIsEditWorkerModalVisible(true);
            }}
          >
            Изменить
          </Button>
          <Popconfirm
            title="Вы уверены, что хотите удалить этого сотрудника?"
            onConfirm={() => handleDelete(record._id)}
            okText="Да"
            cancelText="Нет"
          >
            <Button danger>Удалить</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  if (loading) return <Spin tip="Загрузка сотрудников..." />;

  return (
    <>
      <Table
        dataSource={workers}
        columns={columns}
        rowKey="_id"
        style={{ width: "80%", marginTop: 50 }}
        loading={loading}
        pagination={false}
        bordered
        title={() => (
          <Flex justify="space-between">
            Сотрудники
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setIsNewWorkerModalVisible(true)}
            >
              Новый
            </Button>
          </Flex>
        )}
      />
      <NewWorker
        visible={isNewWorkerModalVisible}
        onClose={() => setIsNewWorkerModalVisible(false)}
        onCreateWorker={handleCreateWorker}
        loading={workerLoading}
      />
      {selectedWorker && (
        <EditWorker
          visible={isEditWorkerModalVisible}
          onClose={() => setIsEditWorkerModalVisible(false)}
          onUpdateWorker={handleUpdateWorker}
          loading={workerLoading}
          initialValues={{
            name: selectedWorker.name,
            canAddProducts: selectedWorker.canAddProducts,
            canDeleteProducts: selectedWorker.canDeleteProducts,
            canModifyProducts: selectedWorker.canModifyProducts,
          }}
        />
      )}
    </>
  );
}
