import axios from "axios";
import { API } from "../config/config";

const $api = axios.create({
  baseURL: API,
  withCredentials: false,
});

const getAccessToken = () => localStorage.getItem("token");
const getRefreshToken = () => localStorage.getItem("refreshToken");
const getUserType = () => {
  const state = localStorage.getItem("authState");
  if (state) {
    const parsedState = JSON.parse(state);
    return parsedState.userType;
  }
  return null;
};

let isRefreshing = false;
let refreshSubscribers: any[] = [];

const onRefreshed = (token: any) => {
  refreshSubscribers.map((callback) => callback(token));
  refreshSubscribers = [];
};

const addRefreshSubscriber = (callback: (token: any) => void) => {
  refreshSubscribers.push(callback);
};

$api.interceptors.request.use((config) => {
  const token = getAccessToken();
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

$api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response?.status === 401 &&
      !originalRequest._isRetry &&
      window.location.pathname !== "/login"
    ) {
      originalRequest._isRetry = true;

      if (!isRefreshing) {
        isRefreshing = true;
        const refreshToken = getRefreshToken();

        try {
          const { data } = await $api.post(`/refresh`, {
            refreshToken,
            userType: getUserType(),
          });

          localStorage.setItem("token", data.token);
          localStorage.setItem("refreshToken", data.refreshToken);

          onRefreshed(data.token);

          isRefreshing = false;
          return $api.request(originalRequest);
        } catch (err) {
          isRefreshing = false;
          return Promise.reject(err);
        }
      }

      return new Promise((resolve) => {
        addRefreshSubscriber((token) => {
          originalRequest.headers["Authorization"] = `Bearer ${token}`;
          resolve($api.request(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  }
);

export default $api;
