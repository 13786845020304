import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Typography,
  Space,
  InputNumber,
  Form,
  Modal,
  message,
  Spin,
} from "antd";
import { useAuth } from "../../contexts/AuthContext/auth.context";
import $api from "../../api/api";

const { Title, Text } = Typography;

export default function FreeDelivery() {
  const { authState, setAuthState } = useAuth();
  const { freeDelivery, userType } = authState || {};
  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm();
  const [estimatedDeliveryCost, setEstimatedDeliveryCost] = useState<
    number | null
  >(null);
  const [hasFreeDelivery, setHasFreeDelivery] = useState<boolean>(
    freeDelivery !== null
  );
  const [loading, setLoading] = useState<boolean>(false);

  const handleEdit = () => setIsEditing(true);
  const handleCancel = () => setIsEditing(false);

  const handleDisableFreeDelivery = async () => {
    try {
      setLoading(true);
      await $api.put(`/seller/free-delivery`, {
        hasFreeDelivery: false,
      });
      setHasFreeDelivery(false);
      setAuthState((prevState) => {
        const updatedAuthState = {
          ...prevState,
          freeDelivery: undefined,
        };
        localStorage.setItem("authState", JSON.stringify(updatedAuthState));
        return updatedAuthState;
      });
      message.success("Бесплатная доставка отключена");
    } catch (error) {
      console.error("Ошибка при отключении бесплатной доставки:", error);
      message.error("Не удалось отключить бесплатную доставку");
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (values: typeof freeDelivery) => {
    try {
      setLoading(true);
      const res = await $api.put(`/seller/free-delivery`, {
        hasFreeDelivery: true,
        ...values,
      });
      message.success("Условия бесплатной доставки обновлены");
      setHasFreeDelivery(true);
      setIsEditing(false);

      setEstimatedDeliveryCost(null);
      setAuthState((prevState) => ({
        ...prevState,
        freeDelivery: res.data.freeDelivery,
      }));
      await fetchEstimatedDeliveryCost();
    } catch (error) {
      console.error(
        "Ошибка при обновлении условий бесплатной доставки:",
        error
      );
      message.error("Не удалось обновить условия бесплатной доставки");
    } finally {
      setLoading(false);
    }
  };

  const fetchEstimatedDeliveryCost = async () => {
    if (hasFreeDelivery && freeDelivery) {
      try {
        const response = await $api.get(`/seller/estimated-amount`, {
          params: {
            weight: freeDelivery.weight || 0,
            radius: freeDelivery.radius || 0,
            userType,
          },
        });
        setEstimatedDeliveryCost(
          parseFloat(response.data.estimatedDeliveryCost)
        );
      } catch (error) {
        console.error("Ошибка при получении средней цены доставки:", error);
      }
    }
  };

  useEffect(() => {
    if (hasFreeDelivery) {
      fetchEstimatedDeliveryCost();
    }
  }, [freeDelivery, hasFreeDelivery]);

  return (
    <Card
      bordered={false}
      style={{
        width: "50%",
        borderRadius: 12,
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        marginTop: 24,
      }}
    >
      {loading ? (
        <Spin size="large" />
      ) : (
        <>
          {authState?.freeDelivery ? (
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
              <Title level={4}>Условия бесплатной доставки</Title>
              <Text>
                <strong>Минимальная сумма заказа:</strong>{" "}
                {freeDelivery?.minCost} ₽
              </Text>
              <Text>
                <strong>Радиус доставки:</strong> {freeDelivery?.radius} км
              </Text>
              <Text>
                <strong>Максимальный вес:</strong>{" "}
                {((freeDelivery?.weight as number) / 1000).toFixed(2)} кг
              </Text>
              {estimatedDeliveryCost !== null && (
                <Text>
                  <strong>Средняя цена доставки:</strong>{" "}
                  {estimatedDeliveryCost.toFixed(2)} ₽
                </Text>
              )}

              {userType === "seller" && (
                <Space>
                  <Button type="link" onClick={handleEdit}>
                    Редактировать
                  </Button>
                  <Button type="default" onClick={handleDisableFreeDelivery}>
                    Отключить
                  </Button>
                </Space>
              )}
            </Space>
          ) : (
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
              <Text>У вас нет бесплатной доставки</Text>
              {userType === "seller" && (
                <Button type="primary" onClick={() => setIsEditing(true)}>
                  Добавить
                </Button>
              )}
            </Space>
          )}

          <Modal
            title="Редактирование условий бесплатной доставки"
            open={isEditing}
            onCancel={handleCancel}
            onOk={() => form.submit()}
          >
            <Form
              form={form}
              layout="vertical"
              initialValues={freeDelivery}
              onFinish={handleSave}
            >
              <Form.Item
                label="Минимальная сумма заказа (₽)"
                name="minCost"
                rules={[
                  {
                    required: true,
                    message: "Введите минимальную сумму заказа",
                  },
                ]}
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                label="Радиус доставки (км)"
                name="radius"
                rules={[{ required: true, message: "Введите радиус доставки" }]}
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                label="Максимальный вес (г)"
                name="weight"
                rules={[
                  { required: true, message: "Введите максимальный вес" },
                ]}
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Form>
          </Modal>
        </>
      )}
    </Card>
  );
}
