import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  message,
  Spin,
  Image,
} from "antd";
import {
  UploadOutlined,
  PlusOutlined,
  DeleteOutlined,
  RobotOutlined,
} from "@ant-design/icons";
import DescriptionEditor from "../DescriptionEditor/DescriptionEditor.component";
import useOpenAI from "../../hooks/useOpenAI";
import useGetRequest from "../../hooks/useGetRequest";
import { useAuth } from "../../contexts/AuthContext/auth.context";
import { Chapter } from "../../types/chapter.type";

const { Option } = Select;

interface NewProductModalProps {
  visible: boolean;
  onClose: () => void;
  onAddProduct: (product: FormData) => void;
}

const NewProductModal: React.FC<NewProductModalProps> = ({
  visible,
  onClose,
  onAddProduct,
}) => {
  const { authState } = useAuth();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<File[]>([]);
  const [options, setOptions] = useState<
    { title: string; choice: string; price: number }[]
  >([{ title: "", choice: "", price: 0 }]);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState<string>("");
  const uploadRef = useRef<HTMLInputElement>(null);
  const { generateDescription, loading: generatingDescription } = useOpenAI();
  const { data, loading: chaptersLoading } = useGetRequest(
    `/chapter/my?userType=${authState?.userType}`
  );
  const [chapters, setChapters] = useState<Chapter[]>([]);

  useEffect(() => {
    if (data) {
      setChapters(data.chapters);
    }
  }, [data]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesToAdd = Array.from(e.target.files).slice(
        0,
        8 - fileList.length
      );
      setFileList([...fileList, ...filesToAdd]);
    }
  };

  const handleDeleteFile = (index: number) => {
    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const handleAddOption = () => {
    setOptions([...options, { title: "", choice: "", price: 0 }]);
  };

  const handleOptionChange = (index: number, field: string, value: any) => {
    const updatedOptions = [...options];
    updatedOptions[index] = { ...updatedOptions[index], [field]: value };
    setOptions(updatedOptions);
  };

  const handleRemoveOption = (index: number) => {
    setOptions(options.filter((_, i) => i !== index));
  };

  const handleGenerateDescription = async () => {
    const title = form.getFieldValue("title");
    if (!title) {
      message.error("Пожалуйста, введите название товара!");
      return;
    }
    const text = await generateDescription(title);
    if (!text) {
      message.error("Ошибка при генерации описания.");
    } else {
      setDescription(text);
    }
  };

  const handleFinish = async (values: any) => {
    if (
      options.length === 0 ||
      options.some((opt) => !opt.title || !opt.choice)
    ) {
      message.error(
        "Пожалуйста, добавьте хотя бы одну опцию с заголовком и выбором!"
      );
      return;
    }

    if (fileList.length === 0) {
      message.error("Пожалуйста, загрузите хотя бы одну фотографию товара!");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("chapterId", values.chapterId);
    formData.append("description", description);
    formData.append("weight", (values.weight * 1000).toString());

    fileList.forEach((file) => formData.append("photos", file));

    options.forEach((opt, index) => {
      formData.append(`options[${index}][title]`, opt.title);
      formData.append(`options[${index}][choice]`, opt.choice);
      formData.append(`options[${index}][price]`, opt.price.toString());
    });

    try {
      await onAddProduct(formData);
      form.resetFields();
      setFileList([]);
      setOptions([{ title: "", choice: "", price: 0 }]);
      setDescription("");
      onClose();
    } catch (error) {
      message.error(
        "Ошибка при добавлении товара. Пожалуйста, попробуйте еще раз."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      title="Добавить новый товар"
      width={600}
    >
      <Spin spinning={loading || generatingDescription}>
        <Form form={form} onFinish={handleFinish} layout="vertical">
          <Form.Item
            name="title"
            label="Название"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите название товара!",
              },
            ]}
          >
            <Input placeholder="Введите название товара" />
          </Form.Item>
          <Form.Item
            name="chapterId"
            label="Раздел"
            rules={[
              { required: true, message: "Пожалуйста, выберите раздел!" },
            ]}
          >
            <Select placeholder="Выберите раздел" loading={chaptersLoading}>
              {chapters.map((chapter: Chapter) => (
                <Option key={chapter._id} value={chapter._id}>
                  {chapter.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="description" label="Описание">
            <DescriptionEditor
              description={description}
              setDescription={setDescription}
            />
            <Button
              type="default"
              icon={<RobotOutlined />}
              onClick={handleGenerateDescription}
              loading={generatingDescription}
              style={{ marginTop: "10px" }}
            >
              Генерировать описание
            </Button>
          </Form.Item>
          <Form.Item
            name="weight"
            label="Вес (кг)"
            rules={[
              { required: true, message: "Пожалуйста, введите вес товара!" },
            ]}
          >
            <InputNumber min={0} step={0.1} placeholder="Введите вес товара" />
          </Form.Item>
          <Form.Item name="photos" label="Фотографии">
            <div>
              <div style={{ flex: 1 }}>
                <input
                  type="file"
                  ref={uploadRef}
                  multiple
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <label htmlFor="file-input">
                  <Button
                    icon={<UploadOutlined />}
                    onClick={() => uploadRef.current?.click()}
                  >
                    Загрузить фотографии
                  </Button>
                </label>
              </div>
              <div
                style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}
              >
                {fileList.map((file, index) => (
                  <div
                    key={index}
                    style={{
                      position: "relative",
                      marginRight: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Image
                      src={URL.createObjectURL(file)}
                      alt={`file-preview-${index}`}
                      style={{
                        width: 120,
                        height: 80,
                        objectFit: "cover",
                        borderRadius: "4px",
                      }}
                    />
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => handleDeleteFile(index)}
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        zIndex: 1,
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Form.Item>
          <Form.Item label="Опции">
            {options.map((opt, index) => (
              <div key={index} style={{ marginBottom: "10px" }}>
                <Input
                  placeholder="Заголовок опции. Например: Размер"
                  value={opt.title}
                  onChange={(e) =>
                    handleOptionChange(index, "title", e.target.value)
                  }
                  style={{ marginBottom: "5px" }}
                />
                <Input
                  placeholder="Выбор опции. Например: Small, Standard"
                  value={opt.choice}
                  onChange={(e) =>
                    handleOptionChange(index, "choice", e.target.value)
                  }
                  style={{ marginBottom: "5px" }}
                />
                <InputNumber
                  min={1}
                  placeholder="Цена (руб)"
                  value={opt.price}
                  onChange={(value) =>
                    handleOptionChange(index, "price", value)
                  }
                  style={{ width: "50%", marginRight: "10px" }}
                />
                <Button
                  type="dashed"
                  onClick={() => handleRemoveOption(index)}
                  style={{ marginTop: "5px" }}
                  disabled={options.length === 1}
                >
                  Удалить опцию
                </Button>
              </div>
            ))}
            <Button
              icon={<PlusOutlined />}
              onClick={handleAddOption}
              type="dashed"
              style={{ width: "100%", marginBottom: "10px" }}
            >
              Добавить опцию
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Добавить
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default NewProductModal;
