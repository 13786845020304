export enum WithdrawalStatus {
  Pending = "Ожидается",
  Rejected = "Отклонено",
  Completed = "Завершено",
}
export enum UserType {
  Courier = "Курьер",
  Seller = "Продавец",
}

export interface WithdrawalRequest {
  _id: string;
  owner: string;
  amount: number;
  cardNumber: number;
  status: WithdrawalStatus;
  name: string;
  phone: string;
  userType: UserType;
  date: string;
}
