import React, { useState } from "react";
import { Card, Button, Typography, Divider, Row, Col } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { Vacancy } from "../../types/vacancy.type";
import EditVacancy from "../EditVacancy/editVacancy.component";

const { Title, Paragraph, Text } = Typography;

interface VacancyCardProps {
  vacancy: Vacancy;
  onOpenApplications: (vacancy: Vacancy) => void;
  onDeleteVacancy: (vacancyId: string) => void;
  onUpdateVacancy: (vacancyId: string, vacancyData: Partial<Vacancy>) => void;
}

export default function VacancyCard({
  vacancy,
  onOpenApplications,
  onDeleteVacancy,
  onUpdateVacancy,
}: VacancyCardProps) {
  const [deleting, setDeleting] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);
    try {
      await onDeleteVacancy(vacancy._id);
    } finally {
      setDeleting(false);
    }
  };

  const handleOpenApplications = () => {
    onOpenApplications(vacancy);
  };

  return (
    <>
      <Card
        style={{
          marginBottom: 16,
          borderRadius: 8,
          height: 380,
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        }}
        actions={[
          <Button
            type="text"
            icon={<FileSearchOutlined />}
            onClick={handleOpenApplications}
          />,
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => setVisible(true)}
          />,
          <Button
            type="text"
            danger
            icon={<DeleteOutlined />}
            onClick={handleDelete}
            loading={deleting}
          />,
        ]}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Title level={4} style={{ marginBottom: 0 }}>
              {vacancy.title}
            </Title>
          </Col>
          <Col span={24}>
            <Divider style={{ margin: "8px 0" }} />
          </Col>
          <Col span={12}>
            <Paragraph>
              <Text strong>График:</Text> {vacancy.schedule}
            </Paragraph>
          </Col>
          <Col span={12}>
            <Paragraph>
              <Text strong>Ставка:</Text> {vacancy.salary.toLocaleString()}₽
              <Text> (в {vacancy.salaryPer})</Text>
            </Paragraph>
          </Col>
          <Col span={24}>
            <Paragraph ellipsis={{ rows: 2 }}>
              <Text strong>Требования:</Text> {vacancy.requirements}
            </Paragraph>
          </Col>
          <Col span={24}>
            <Paragraph ellipsis={{ rows: 2 }}>
              <Text strong>Обязанности:</Text> {vacancy.responsibilities}
            </Paragraph>
          </Col>
        </Row>
      </Card>
      <EditVacancy
        visible={visible}
        onClose={() => setVisible(false)}
        onUpdateVacancy={onUpdateVacancy}
        vacancy={vacancy}
      />
    </>
  );
}
