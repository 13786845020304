import { useState } from "react";
import { useAuth } from "../contexts/AuthContext/auth.context";
import { Address } from "../types/address.type";
import { ISchedule } from "../types/schedule.type";
import $api from "../api/api";

interface FormDataProps {
  category: number | null;
  subcategory: string | null;
  photo: File | null;
  banner: File | null;
  address: Address | null;
  email: string | null;
  name: string | null;
  schedule: ISchedule | null;
}

export const useFillProfile = () => {
  const { setAuthState } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const submitForm = async (data: FormDataProps): Promise<boolean> => {
    setLoading(true);
    setError(null);

    const formData = new FormData();

    if (data.category) {
      formData.append("category", data.category.toString());
    }

    if (data.subcategory) {
      formData.append("subcategoryId", data.subcategory);
    }

    if (data.photo) {
      formData.append("photo", data.photo);
    }

    if (data.banner) {
      formData.append("banner", data.banner);
    }
    if (data.email) {
      formData.append("email", data.email);
    }

    if (data.schedule) {
      data.schedule.forEach((daySchedule, index) => {
        formData.append(`schedule[${index}][dayOfWeek]`, daySchedule.dayOfWeek);
        formData.append(
          `schedule[${index}][openTime]`,
          daySchedule.openTime || ""
        );
        formData.append(
          `schedule[${index}][closeTime]`,
          daySchedule.closeTime || ""
        );
        formData.append(
          `schedule[${index}][isDayOff]`,
          String(daySchedule.isDayOff)
        );
        formData.append(
          `schedule[${index}][is24Hours]`,
          String(daySchedule.is24Hours)
        );
      });
    }

    if (data.name) {
      formData.append("name", data.name);
    }
    if (data.address) {
      formData.append("address[city]", data.address.city);
      formData.append("address[country]", data.address.country);
      formData.append("address[street]", data.address.street);
      formData.append("address[latitude]", data.address.latitude.toString());
      formData.append("address[longitude]", data.address.longitude.toString());
      if (data.address.entrance)
        formData.append("address[entrance]", data.address.entrance);
      if (data.address.floor)
        formData.append("address[floor]", data.address.floor.toString());
      if (data.address.house)
        formData.append("address[house]", data.address.house);
      if (data.address.apartment)
        formData.append("address[apartment]", data.address.apartment);
    }

    try {
      const response = await $api.put(`/seller/change-me`, formData);
      const userData = response.data.updatedSeller;
      setAuthState((prevState) => ({
        ...prevState,
        ...userData,
      }));
      return true;
    } catch (err: any) {
      setError(
        err.response?.data?.error.error || err.message || "An error occurred"
      );
      console.error(
        "Error submitting form:",
        err.response?.data?.message || err.message || "An error occurred"
      );
      return false;
    } finally {
      setLoading(false);
    }
  };

  return { submitForm, loading, error };
};
