import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button } from "antd";
import { DishonestWorker } from "../../types/worker.type";

interface EditWorkerProps {
  visible: boolean;
  onClose: () => void;
  worker: DishonestWorker;
  onUpdateWorker: (
    workerId: string,
    workerData: Partial<DishonestWorker>
  ) => void;
}

const EditDishonestWorker: React.FC<EditWorkerProps> = ({
  visible,
  onClose,
  worker,
  onUpdateWorker,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        name: worker.name,
        surname: worker.surname,
        phone: worker.phone ? worker.phone.replace("+7", "") : "",
        reason: worker.reason,
      });
    }
  }, [visible, worker, form]);

  const handleSubmit = async (values: Partial<DishonestWorker>) => {
    setLoading(true);
    try {
      await onUpdateWorker(worker._id, values);
      onClose();
    } finally {
      setLoading(false);
    }
  };
  const formatPhoneNumber = (value: string) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  return (
    <Modal
      visible={visible}
      title="Изменить недобросовестного сотрудника"
      onCancel={onClose}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          name: worker.name,
          surname: worker.surname,
          phone: worker.phone ? worker.phone.replace("+7", "") : "",
          reason: worker.reason,
        }}
      >
        <Form.Item
          label="Имя"
          name="name"
          rules={[
            { required: true, message: "Пожалуйста, введите имя сотрудника." },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Фамилия"
          name="surname"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите фамилию сотрудника.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Телефон"
          name="phone"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите номер сотрудника.",
            },
          ]}
        >
          <Input
            addonBefore="+7"
            onChange={(e) => {
              const formattedPhoneNumber = formatPhoneNumber(e.target.value);
              form.setFieldsValue({ phone: formattedPhoneNumber });
            }}
            style={{ marginBottom: 15 }}
          />
        </Form.Item>

        <Form.Item
          label="Причина"
          name="reason"
          rules={[
            {
              max: 150,
              message: "Причина не может содежать больше 150 символов.",
            },
            { required: true, message: "Пожалуйста, укажите причину." },
          ]}
        >
          <Input.TextArea rows={4} showCount={true} maxLength={150} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditDishonestWorker;
