import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Space,
  Image,
  AutoComplete,
  message,
  Spin,
  TimePicker,
  Switch,
  Typography,
  Row,
  Col,
} from "antd";
import { useAuth } from "../../contexts/AuthContext/auth.context";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { Map, Marker, MapRef } from "react-map-gl/maplibre";
import { useGeocode } from "../../hooks/useGeocode";
import { useSuggestions } from "../../hooks/useSuggestions";
import { categories } from "../../constants/categories.constant";
import { Address } from "../../types/address.type";
import { API } from "../../config/config";
import { useFillProfile } from "../../hooks/useFillProfile";
import dayjs from "dayjs";
import { DaysOfWeek, IDaySchedule, ISchedule } from "../../types/schedule.type";
import useGetRequest from "../../hooks/useGetRequest";
import { Subcategory } from "../../types/subcategory.type";

interface EditProfileProps {
  visible: boolean;
  onClose: () => void;
}

const EditProfile: React.FC<EditProfileProps> = ({ visible, onClose }) => {
  const { authState } = useAuth();
  const [form] = Form.useForm();
  const { submitForm, loading, error } = useFillProfile();
  const [avatar, setAvatar] = useState<File | null>(null);
  const [banner, setBanner] = useState<File | null>(null);
  const [avatarPreview, setAvatarPreview] = useState<string | null>(null);
  const [bannerPreview, setBannerPreview] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [marker, setMarker] = useState<{ lat: number; lon: number }>();
  const [addressComponents, setAddressComponents] = useState<Address | null>(
    authState?.address || null
  );
  const [schedule, setSchedule] = useState<ISchedule>([]);
  const mapRef = useRef<MapRef>(null);
  const avatarInputRef = useRef<HTMLInputElement>(null);
  const bannerInputRef = useRef<HTMLInputElement>(null);
  const daysArray = Object.entries(DaysOfWeek).map(([key, value]) => ({
    day: value,
  }));
  const { geocodeLocation } = useGeocode();
  const { options: autocompleteOptions } = useSuggestions({
    query: searchQuery,
    isLocality: false,
  });
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const { data: subcategories, loading: subcategoriesLoading } = useGetRequest(
    `/subcategory/${selectedCategory || 1}`
  );

  useEffect(() => {
    form.resetFields(["subcategory"]);
  }, [selectedCategory]);
  useEffect(() => {
    if (authState?.photoUri) {
      setAvatarPreview(`${API}/${authState.photoUri}`);
    }
    if (authState?.banner) {
      setBannerPreview(`${API}/${authState.banner}`);
    }
  }, [authState?.photoUri, authState?.banner]);
  useEffect(() => {
    if (authState?.category) {
      const selectedCategory = categories.find(
        (category) => category.id === Number(authState?.category)
      );
      form.setFieldsValue({ category: selectedCategory?.name });
    }
  }, [authState?.category, form]);
  useEffect(() => {
    if (authState?.address) {
      const { latitude, longitude } = authState.address;
      if (latitude && longitude) {
        setMarker({ lat: latitude, lon: longitude });
        mapRef.current?.setCenter({ lat: latitude, lon: longitude });
        mapRef.current?.setZoom(16);
        setSearchQuery(
          `${authState.address.street}, ${authState.address.city}, ${authState.address.country}`
        );
      }
    }
  }, [authState?.address]);
  useEffect(() => {
    form.setFieldsValue({
      address: {
        city: addressComponents?.city || "",
        country: addressComponents?.country || "",
        street: addressComponents?.street || "",
        latitude: addressComponents?.latitude || "",
        longitude: addressComponents?.longitude || "",
        entrance: addressComponents?.entrance || "",
        floor: addressComponents?.floor || "",
        apartment: addressComponents?.apartment || "",
        house: addressComponents?.house || "",
      },
    });
  }, [addressComponents, form]);

  useEffect(() => {
    const initializeSchedule = () => {
      if (authState?.schedule && authState.schedule.length > 0) {
        return authState.schedule;
      }

      return daysArray.map((day) => ({
        dayOfWeek: day.day,
        is24Hours: false,
        isDayOff: false,
        openTime: "00:00",
        closeTime: "00:00",
      }));
    };

    setSchedule(initializeSchedule());
  }, [authState?.schedule]);

  const handleFinish = async (values: any) => {
    const requestData: any = {
      category: selectedCategory,
      subcategory: values.subcategory,
      photo: avatar || null,
      banner: banner || null,
      address: addressComponents || null,
      name: values.name || null,
      schedule: schedule,
    };

    console.log(requestData);

    if (values.email !== authState?.email) {
      requestData.email = values.email;
    }

    const success = await submitForm(requestData);

    if (success) {
      message.success("Профиль успешно обновлен");
      onClose();
    } else {
      message.error(error);
    }
  };

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setAvatar(file);
      const reader = new FileReader();
      reader.onload = () => {
        setAvatarPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBannerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setBanner(file);
      const reader = new FileReader();
      reader.onload = () => {
        setBannerPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddressSelect = async (value: string) => {
    const selectedOption = autocompleteOptions.find(
      (option) => option.value === value
    );

    if (selectedOption) {
      const data = await geocodeLocation(selectedOption.value);
      const address = data[0].address;

      const addressData = {
        city: address.city || address.town || address.village || "",
        country: address.country || "",
        street: address.road || data.address.pedestrian || "",
        latitude: data[0].lat,
        longitude: data[0].lon,
        house: address.house_number || "",
      };

      setAddressComponents(addressData);
      mapRef.current?.setCenter({ lat: data[0].lat, lon: data[0].lon });
      mapRef.current?.setZoom(16);
      setMarker({ lat: data[0].lat, lon: data[0].lon });
    }
  };

  const handleMapClick = async (event: maplibregl.MapMouseEvent) => {
    const { lngLat } = event;
    const newMarker = { lat: lngLat.lat, lon: lngLat.lng };
    setMarker(newMarker);
    const data = await geocodeLocation(`${lngLat.lat},${lngLat.lng}`);
    const address = data[0].address;

    const addressData = {
      city: address.city || address.town || address.village || "",
      country: address.country || "",
      street: address.road || data.address.pedestrian || "",
      latitude: lngLat.lat,
      longitude: lngLat.lng,
      house: address.house_number || "",
    };

    setAddressComponents(addressData);
    const searchQuery = `${addressData.country} , ${addressData.city} , ${addressData.street} , ${addressData.house}`;
    setSearchQuery(searchQuery);
  };

  const handleScheduleChange = (day: string, field: string, value: any) => {
    if (field === "openTime" || field === "closeTime") {
      if (isDayjs(value)) {
        value = value.format("HH:mm");
      } else {
        value = dayjs(value).format("HH:mm");
      }
    }
    setSchedule((prevSchedule) =>
      prevSchedule.map((item) =>
        item.dayOfWeek === day ? { ...item, [field]: value } : item
      )
    );
  };

  const isDayjs = (value: any): value is dayjs.Dayjs => {
    return dayjs.isDayjs(value);
  };

  return (
    <Modal
      title="Редактировать профиль"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={600}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            name: authState?.name || "",
            category: authState?.category || "",
            email: authState?.email || "",
            address: {
              city: authState?.address?.city || "",
              country: authState?.address?.country || "",
              street: authState?.address?.street || "",
              latitude: authState?.address?.latitude || "",
              longitude: authState?.address?.longitude || "",
              entrance: authState?.address?.entrance || "",
              floor: authState?.address?.floor || "",
              apartment: authState?.address?.apartment || "",
              house: authState?.address?.house || "",
            },
            schedule: schedule,
          }}
          onFinish={handleFinish}
        >
          <Form.Item
            label="Имя"
            name="name"
            rules={[{ required: true, message: "Введите имя" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Категория"
            name="category"
            rules={[{ required: true, message: "Выберите категорию" }]}
          >
            <Select onChange={(category) => setSelectedCategory(category)}>
              {categories.map((category) => (
                <Select.Option key={category.id} value={category.id}>
                  {category.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {selectedCategory && (
            <Form.Item
              label="Подкатегория"
              name="subcategory"
              rules={[{ required: true, message: "Выберите подкатегорию" }]}
            >
              <Select loading={subcategoriesLoading}>
                {subcategories.map((subcategory: Subcategory) => (
                  <Select.Option key={subcategory._id} value={subcategory._id}>
                    {subcategory.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item label="Email" name="email">
            <Input type="email" />
          </Form.Item>

          <Form.Item
            label="Фотография"
            name="photo"
            rules={[
              {
                required: !authState?.photoUri,
                message: "Загрузите фотографию",
              },
            ]}
          >
            <Space direction="vertical" size="middle">
              {avatarPreview && <Image width={100} src={avatarPreview} />}
              <Button onClick={() => avatarInputRef.current?.click()}>
                Загрузить фотографию
              </Button>
              <input
                type="file"
                accept="image/*"
                ref={avatarInputRef}
                style={{ display: "none" }}
                onChange={handleAvatarChange}
              />
            </Space>
          </Form.Item>

          <Form.Item
            label="Баннер"
            name="banner"
            rules={[
              { required: !authState?.banner, message: "Загрузите баннер" },
            ]}
          >
            <Space direction="vertical" size="middle">
              {bannerPreview && <Image width={200} src={bannerPreview} />}
              <Button onClick={() => bannerInputRef.current?.click()}>
                Загрузить баннер
              </Button>
              <input
                type="file"
                accept="image/*"
                ref={bannerInputRef}
                style={{ display: "none" }}
                onChange={handleBannerChange}
              />
            </Space>
          </Form.Item>
          <Form.Item label="Расписание">
            {daysArray.map((item) => {
              const daySchedule: IDaySchedule = schedule.find(
                (s) => s.dayOfWeek === item.day
              ) ?? {
                dayOfWeek: item.day,
                is24Hours: false,
                isDayOff: false,
                openTime: "00:00",
                closeTime: "00:00",
              };

              const is24Hours = daySchedule.is24Hours || false;
              const isDayOff = daySchedule.isDayOff || false;

              const handleSwitchChange = (
                key: keyof IDaySchedule,
                value: boolean
              ) => {
                if (key === "is24Hours" && value) {
                  handleScheduleChange(item.day, "isDayOff", false);
                } else if (key === "isDayOff" && value) {
                  handleScheduleChange(item.day, "is24Hours", false);
                }
                handleScheduleChange(item.day, key, value);
              };

              return (
                <Form.Item key={item.day} name={["schedule", item.day]}>
                  <Typography.Text>{item.day}</Typography.Text>
                  <Row gutter={16} align="middle" style={{ marginBottom: 16 }}>
                    <Col span={8}>
                      <Switch
                        checked={is24Hours}
                        onChange={(checked) =>
                          handleSwitchChange("is24Hours", checked)
                        }
                      />
                      <Typography.Text
                        type="secondary"
                        style={{ marginLeft: 8 }}
                      >
                        Открыто 24 часа
                      </Typography.Text>
                    </Col>
                    <Col span={8}>
                      <Switch
                        checked={isDayOff}
                        onChange={(checked) =>
                          handleSwitchChange("isDayOff", checked)
                        }
                      />
                      <Typography.Text
                        type="secondary"
                        style={{ marginLeft: 8 }}
                      >
                        Выходной
                      </Typography.Text>
                    </Col>
                    <Col span={8}>
                      {!is24Hours && !isDayOff && (
                        <Space direction="horizontal">
                          <TimePicker
                            format="HH:mm"
                            value={dayjs(
                              daySchedule.openTime || "00:00",
                              "HH:mm"
                            )}
                            onChange={(time) =>
                              handleScheduleChange(item.day, "openTime", time)
                            }
                          />
                          <TimePicker
                            format="HH:mm"
                            value={dayjs(
                              daySchedule.closeTime || "00:00",
                              "HH:mm"
                            )}
                            onChange={(time) =>
                              handleScheduleChange(item.day, "closeTime", time)
                            }
                          />
                        </Space>
                      )}
                    </Col>
                  </Row>
                </Form.Item>
              );
            })}
          </Form.Item>

          <Form.Item
            label="Адрес"
            rules={[{ required: true, message: "Введите адрес" }]}
          >
            <AutoComplete
              style={{ width: "100%" }}
              options={autocompleteOptions}
              onSelect={handleAddressSelect}
              onChange={(value) => setSearchQuery(value)}
              placeholder="Введите адрес"
              value={searchQuery}
            />

            <Map
              ref={mapRef}
              mapLib={maplibregl}
              initialViewState={{
                latitude: 55.751244,
                longitude: 37.618423,
                zoom: 14,
              }}
              style={{
                width: "100%",
                height: 400,
                borderRadius: 10,
                marginTop: 20,
              }}
              mapStyle="https://api.maptiler.com/maps/streets/style.json?key=CbTos9Xm0oDHR3dYNnVW"
              onClick={handleMapClick}
            >
              {marker && (
                <Marker
                  longitude={marker.lon}
                  latitude={marker.lat}
                  color="red"
                />
              )}
            </Map>

            {addressComponents && (
              <>
                <Form.Item
                  label="Этаж"
                  name={["address", "floor"]}
                  rules={[{ required: true, message: "Выберите этаж" }]}
                  initialValue={addressComponents.floor}
                >
                  <Input
                    placeholder="Этаж"
                    onChange={(e) =>
                      setAddressComponents((prev) =>
                        prev
                          ? { ...prev, floor: parseInt(e.target.value) }
                          : prev
                      )
                    }
                    style={{
                      width: "100%",
                      maxWidth: 200,
                      marginTop: 12,
                      marginBottom: 12,
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Квартира"
                  name={["address", "apartment"]}
                  initialValue={addressComponents.apartment}
                  rules={[{ required: true, message: "Выберите квартиру" }]}
                >
                  <Input
                    placeholder="Квартира"
                    onChange={(e) =>
                      setAddressComponents((prev) =>
                        prev ? { ...prev, apartment: e.target.value } : prev
                      )
                    }
                    style={{
                      width: "100%",
                      maxWidth: 200,
                      marginTop: 12,
                      marginBottom: 12,
                      marginLeft: 8,
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Номер дома"
                  name={["address", "house"]}
                  initialValue={addressComponents.house}
                  rules={[{ required: true, message: "Введите номер дома" }]}
                >
                  <Input
                    placeholder="Номер дома"
                    onChange={(e) =>
                      setAddressComponents((prev) =>
                        prev ? { ...prev, house: e.target.value } : prev
                      )
                    }
                    style={{
                      width: "100%",
                      maxWidth: 200,
                      marginTop: 12,
                      marginBottom: 12,
                      marginLeft: 8,
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Подъезд"
                  name={["address", "entrance"]}
                  initialValue={addressComponents.entrance}
                  rules={[
                    { required: true, message: "Введите номер подъезда" },
                  ]}
                >
                  <Input
                    placeholder="Номер подъезда"
                    onChange={(e) =>
                      setAddressComponents((prev) =>
                        prev ? { ...prev, entrance: e.target.value } : prev
                      )
                    }
                    style={{
                      width: "100%",
                      maxWidth: 200,
                      marginTop: 12,
                      marginBottom: 12,
                      marginLeft: 8,
                    }}
                  />
                </Form.Item>
              </>
            )}
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditProfile;
