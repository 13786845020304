import React, { useEffect, useState } from "react";
import { Review } from "../../types/review.type";
import $api from "../../api/api";
import { DatePicker, Image, Select, Space, Table, Typography } from "antd";
import { API } from "../../config/config";
import { useAuth } from "../../contexts/AuthContext/auth.context";

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

export default function Reviews() {
  const { authState } = useAuth();
  const [reviews, setReviews] = useState<Review[]>();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [dateRange, setDateRange] = useState<[any, any] | undefined>(undefined);
  const [sortBy, setSortBy] = useState<string | undefined>(undefined);
  const [order, setOrder] = useState<"asc" | "desc">("desc");

  const fetchReviews = async () => {
    setLoading(true);
    try {
      const response = await $api.get(
        `/review/my?userType=${authState?.userType}`,
        {
          params: {
            page,
            limit: pageSize,
            startDate: dateRange
              ? dateRange[0]?.format("DD.MM.YYYY")
              : undefined,
            endDate: dateRange ? dateRange[1]?.format("DD.MM.YYYY") : undefined,
            sortBy,
            order,
          },
        }
      );

      setTotal(response.data.teal);
      setReviews(response.data.reviews);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, [
    page,
    pageSize,
    sortBy,
    order,
    dateRange,
    dateRange,
    authState?.userType,
  ]);

  const columns = [
    {
      title: "Трек заказа",
      dataIndex: "trackingId",
      key: "trackingId",
    },
    {
      title: "Оценка",
      dataIndex: "rating",
      key: "clientName",
    },
    {
      title: "Заголовок",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Описание",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Дата",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Имя продавца",
      dataIndex: "sellerName",
      key: "sellerName",
    },
    {
      title: "Фото продавца",
      dataIndex: "sellerPhoto",
      key: "sellerPhoto",
      render: (sellerPhoto: string) => (
        <div>
          <Image
            src={`${API}/${sellerPhoto}`}
            width={50}
            preview={{ src: `${API}/${sellerPhoto}` }}
            alt="Фото"
            loading="lazy"
            style={{
              cursor: "pointer",
              borderRadius: 4,
              border: "1px solid #ddd",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            }}
          />
        </div>
      ),
    },
    {
      title: "Имя Клиента",
      dataIndex: "clientName",
      key: "clientName",
    },
    {
      title: "Фото клиента",
      dataIndex: "clientPhoto",
      key: "clientPhoto",
      render: (clientPhoto: string) => (
        <div>
          <Image
            src={`${API}/${clientPhoto}`}
            width={50}
            preview={{ src: `${API}/${clientPhoto}` }}
            alt="Фото"
            loading="lazy"
            style={{
              cursor: "pointer",
              borderRadius: 4,
              border: "1px solid #ddd",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            }}
          />
        </div>
      ),
    },

    {
      title: "Фото товара",
      dataIndex: "photos",
      key: "photos",
      render: (photos: string[]) => (
        <div>
          {photos && photos.length > 0 ? (
            photos.map((photo, index) => (
              <Image
                key={index}
                src={`${API}/${photo}`}
                width={50}
                preview={{ src: `${API}/${photo}` }}
                alt="Фото"
                loading="lazy"
                style={{
                  cursor: "pointer",
                  borderRadius: 4,
                  border: "1px solid #ddd",
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                }}
              />
            ))
          ) : (
            <span>Нет фото</span>
          )}
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: "16px" }}>
      <Title level={2}>Отзывы</Title>

      <Space
        direction="horizontal"
        style={{ marginBottom: 24, flexWrap: "wrap" }}
        align="center"
      >
        <RangePicker
          onChange={(dates) => setDateRange(dates ?? undefined)}
          placeholder={["Начало", "Конец"]}
          format={{ format: "DD.MM.YYYY" }}
        />
        <Select
          placeholder="Сортировать по"
          defaultValue="date"
          style={{ width: 150 }}
          onChange={(value) => setSortBy(value)}
        >
          <Option value="date">Дата</Option>
          <Option value="rating">Оценка</Option>
        </Select>
        <Select
          defaultValue="desc"
          style={{ width: 150 }}
          onChange={(value: "asc" | "desc") => setOrder(value)}
        >
          <Option value="asc">По возрастанию</Option>
          <Option value="desc">По убыванию</Option>
        </Select>
      </Space>
      <Table
        columns={columns}
        dataSource={reviews}
        rowKey={(record) => record._id}
        pagination={{
          current: page,
          total: total,
          pageSize: pageSize,
          onChange: (page, size) => {
            setPage(page);
            if (size !== pageSize) setPageSize(size!);
          },
        }}
        scroll={{ x: "max-content" }}
        loading={loading}
      />
    </div>
  );
}
