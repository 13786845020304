import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { Address } from "../../types/address.type";
import { ISchedule } from "../../types/schedule.type";
import $api from "../../api/api";
import axios from "axios";
import { SellerStatus } from "../../types/worker.type";

interface AuthState {
  token: string | null;
  refreshToken: string | null;
  authenticated: boolean | null;
  userType: "seller" | "worker" | null;
  phone?: string | null;
  _id?: string | null;
  name?: string | null;
  email?: string | null;
  address?: Address | null;
  photoUri?: string | null;
  banner?: string | null;
  schedule?: ISchedule | null;
  category?: number | null;
  subcategory?: string | null;
  subcategoryName?: string | null;
  sellerId?: string | null;
  sellerName?: string | null;
  sellerPhone?: string | null;
  canAddProducts?: boolean | null;
  canDeleteProducts?: boolean | null;
  canModifyProducts?: boolean | null;
  status?: SellerStatus | null;
  isBranch?: boolean;
  sellerBranch?: string;
  balance?: {
    amount: number;
  };
  freeDelivery?: {
    minCost: number;
    radius: number;
    weight: number;
  };
  rating?: number;
  reviewsCount?:number
}
interface AuthContext {
  authState?: AuthState;
  onLogin?: (
    phone: string,
    password: string,
    userType: "seller" | "worker"
  ) => Promise<any>;
  onSendOtp?: (phone: string, password: string) => Promise<any>;
  onVerifyOtp?: (code: string) => Promise<any>;
  setAuthState: React.Dispatch<React.SetStateAction<AuthState>>;
  onSwitchBranch: (id: string) => Promise<any>;
  onLogout: () => Promise<void>;
}
const defaultAuthContext: AuthContext = {
  authState: {
    token: null,
    refreshToken: null,
    authenticated: null,
    userType: null,
  },
  onLogin: async () => Promise.resolve(),
  onSendOtp: async () => Promise.resolve(),
  onVerifyOtp: async () => Promise.resolve(),
  setAuthState: () => {},
  onSwitchBranch: async () => Promise.resolve(),
  onLogout: async () => Promise.resolve(),
};
export const useAuth = () => useContext(AuthContext);

export const AuthContext = createContext(defaultAuthContext);
export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>({
    token: null,
    refreshToken: null,
    authenticated: null,
    userType: null,
  });

  useEffect(() => {
    const loadAuthState = () => {
      const storedAuthState = localStorage.getItem("authState");
      if (storedAuthState) {
        const parsedAuthState = JSON.parse(storedAuthState);
        setAuthState(parsedAuthState);
        return parsedAuthState;
      }
      return null;
    };

    const loadToken = () => {
      const token = localStorage.getItem("token");
      if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      }
      return token;
    };

    const fetchUserData = async (userType: "seller" | "worker") => {
      try {
        const response = await $api.get(`/${userType}/me`);
        return userType === "seller"
          ? response.data.seller
          : response.data.worker;
      } catch (error) {
        console.error("Error fetching user data:", error);
        return null;
      }
    };

    const initializeAuthState = async () => {
      const token = loadToken();
      const localAuthState = loadAuthState();

      if (token && localAuthState?.userType) {
        const userData = await fetchUserData(localAuthState.userType);
        if (userData) {
          const updatedAuthState = {
            ...localAuthState,
            ...userData,
            authenticated: true,
          };
          setAuthState(updatedAuthState);
          localStorage.setItem("authState", JSON.stringify(updatedAuthState));
        }
      } else {
        console.log("No valid token or user type found.");
      }
    };

    initializeAuthState();
  }, []);

  const login = async (
    phone: string,
    password: string,
    userType: "seller" | "worker"
  ) => {
    try {
      const response = await $api.post(`${userType}/login`, {
        phone,
        password,
      });
      const { token, refreshToken } = response.data;
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);

      let userData: any;

      if (userType === "seller") {
        userData = response.data.seller;
      } else if (userType === "worker") {
        userData = response.data.worker;
      }

      setAuthState((prevState) => ({
        ...prevState,
        ...userData,
        token,
        refreshToken,
        authenticated: true,
        userType,
      }));

      localStorage.setItem(
        "authState",
        JSON.stringify({
          ...authState,
          token,
          refreshToken,
          authenticated: true,
          userType,
          ...userData,
        })
      );
      return response.data;
    } catch (error) {
      console.error("Login error:", error);
      return (error as any).response;
    }
  };
  const sendOtp = async (phone: string, password: string) => {
    try {
      const response = await $api.post(`/seller/send-otp`, {
        phone,
        password,
      });

      setAuthState({
        token: null,
        refreshToken: null,
        authenticated: false,
        userType: "seller",
        phone,
      });

      return response.data;
    } catch (error) {
      console.error("Registration error:", error);
      return (error as any).response;
    }
  };
  const verifyOtp = async (code: string) => {
    try {
      const response = await $api.post(`/seller/verify-otp`, {
        phone: authState.phone,
        code,
      });
      const { token, refreshToken } = response.data;
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);

      const userData = response.data.seller;
      setAuthState((prevstate) => ({
        ...prevstate,
        ...userData,
        token,
        refreshToken,
        authenticated: true,
        userType: "seller",
      }));
      localStorage.setItem(
        "authState",
        JSON.stringify({
          ...authState,
          ...userData,
          token,
          refreshToken,
          authenticated: true,
          userType: "seller",
        })
      );
      return response.data;
    } catch (error) {
      console.error("Verification:", error);
      return (error as any).response;
    }
  };
  const switchBranch = async (id: string) => {
    try {
      const response = await $api.post(`/seller/switch/${id}`);
      const { seller, token, refreshToken } = response.data;

      setAuthState((prevState) => ({
        ...prevState,
        ...seller,
        token,
        refreshToken,
        authenticated: true,
      }));

      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      localStorage.setItem("token", token);

      localStorage.setItem(
        "authState",
        JSON.stringify({
          ...authState,
          ...seller,
          token,
          refreshToken,
          authenticated: true,
        })
      );
    } catch (error) {
      console.error("Error switching branch:", error);
    }
  };
  const logout = async () => {
    try {
      await $api.post("/logout", { userType: authState.userType });
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      setAuthState({
        token: null,
        refreshToken: null,
        authenticated: null,
        userType: null,
      });

      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("authState");

      delete axios.defaults.headers.common["Authorization"];
    }
  };

  return (
    <AuthContext.Provider
      value={{
        onLogin: login,
        onSendOtp: sendOtp,
        onVerifyOtp: verifyOtp,
        onSwitchBranch: switchBranch,
        onLogout: logout,
        authState,
        setAuthState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
