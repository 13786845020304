import { useState } from "react";
import $api from "../api/api";

export interface EditWorkerValues {
  name: string;
  canAddProducts: boolean;
  canDeleteProducts: boolean;
  canModifyProducts: boolean;
}

export const useWorker = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const createWorker = async (workerData: {
    name: string;
    phone: string;
    password: string;
    canAddProducts: boolean;
    canDeleteProducts: boolean;
    canModifyProducts: boolean;
  }) => {
    setLoading(true);
    setError(null);
    try {
      const formattedPhone = `+7${workerData.phone}`;
      const response = await $api.post(`/worker/new?userType=seller`, {
        ...workerData,
        phone: formattedPhone,
      });
      setLoading(false);
      return response.data;
    } catch (err) {
      setLoading(false);
      setError("Error creating worker");
      throw err;
    }
  };

  const deleteWorker = async (id: string) => {
    setLoading(true);
    setError(null);
    try {
      await $api.delete(`/worker/delete/${id}`);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("Error deleting worker");
      throw err;
    }
  };

  const updateWorker = async (id: string, workerData: EditWorkerValues) => {
    setLoading(true);
    setError(null);
    try {
      const response = await $api.put(`/worker/${id}/edit`, workerData);
      setLoading(false);
      return response.data;
    } catch (err) {
      setLoading(false);
      setError("Error updating worker");
      throw err;
    }
  };

  return {
    createWorker,
    deleteWorker,
    updateWorker,
    loading,
    error,
  };
};
