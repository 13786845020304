import { useState, useEffect } from "react";
import { message } from "antd";
import { useAuth } from "../contexts/AuthContext/auth.context";
import { Product } from "../types/product.type";
import $api from "../api/api";

export const useProduct = () => {
  const { authState } = useAuth();
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [importing, setImporting] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalCount, SetTotalCount] = useState(0);
  const [search, setSearch] = useState<string>("");
  const [dateRange, setDateRange] = useState<[any, any] | undefined>(undefined);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const response = await $api.get(`/product/my`, {
          params: {
            search,
            limit,
            userType: authState?.userType,
            page: currentPage,
            startDate: dateRange ? dateRange[0]?.format("DD.MM.YY") : undefined,
            endDate: dateRange ? dateRange[1]?.format("DD.MM.YY") : undefined,
          },
        });
        setProducts(response.data.products);
        SetTotalCount(response.data.total);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [authState?.userType, currentPage, search, dateRange]);

  const newProduct = async (product: FormData) => {
    try {
      const response = await $api.post(
        `/product?userType=${authState?.userType}`,
        product
      );
      setProducts([response.data.product, ...products]);
    } catch (error: any) {
      console.error(error);
      message.error(error.response.data.error);
    }
  };

  const deleteProduct = async (productId: string) => {
    try {
      const response = await $api.delete(
        `/product/delete/${productId}?userType=${authState?.userType}`
      );
      message.success(response.data.message);
      setProducts(products.filter((product) => product._id !== productId));
    } catch (error: any) {
      console.error(error);
      message.error(error.response.data.error);
    }
  };

  const updateProduct = async (productId: string, productData: FormData) => {
    try {
      const response = await $api.put(
        `/product/${productId}?userType=${authState?.userType}`,
        productData
      );
      setProducts(
        products.map((product) =>
          product._id === productId ? response.data.product : product
        )
      );
      message.success(response.data.message);
    } catch (error: any) {
      console.error(error);
      message.error(error.response.data.error);
    }
  };

  const importProductsFromJson = async (jsonFile: File) => {
    setImporting(true);
    const formData = new FormData();
    formData.append("file", jsonFile);

    try {
      const response = await $api.post(
        `/product/import?userType=${authState?.userType}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setProducts([...response.data.products, ...products]);
      message.success("Товары успешно импортированы.");
    } catch (error: any) {
      console.error(error);
      message.error(error.response?.data?.error || "Ошибка импорта товаров.");
    } finally {
      setImporting(false);
    }
  };

  return {
    products,
    loading,
    newProduct,
    deleteProduct,
    updateProduct,
    importProductsFromJson,
    importing,
    currentPage,
    setCurrentPage,
    totalCount,
    search,
    setSearch,
    dateRange,
    setDateRange,
    limit,
    setLimit,
  };
};
