import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  message,
  Spin,
  Space,
  Modal,
  Input,
  Form,
  Flex,
} from "antd";
import useGetRequest from "../../hooks/useGetRequest";
import { API } from "../../config/config";
import { Courier, CourierStatus, CourierType } from "../../types/courier.type";
import { useCourier } from "../../hooks/useCourier";
import { PlusOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

export default function Couriers() {
  const { data, loading, refresh } = useGetRequest(`${API}/courier/my-regular`);
  const [couriers, setCouriers] = useState<Courier[]>([]);
  const {
    deleteCourier,
    addCourier,
    loading: courierLoading,
    error,
  } = useCourier();
  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility
  const [phone, setPhone] = useState(""); // State for phone input

  useEffect(() => {
    if (data) {
      setCouriers(data.couriers);
    }
  }, [data]);

  const handleDelete = async (id: string) => {
    try {
      await deleteCourier(id);
      setCouriers(couriers.filter((courier) => courier._id !== id));
      message.success("Курьер успешно удален");
    } catch (error) {
      message.error("Ошибка при удалении курьера");
    }
  };

  const handleAddCourier = async () => {
    try {
      await addCourier(phone); // Try to add the courier
      message.success("Курьер успешно добавлен."); // Show success message
      setIsModalVisible(false); // Close the modal only if successful
      setPhone(""); // Reset phone input after successful addition
      refresh(); // Optionally refresh the courier list
    } catch (err) {
      message.error(error);
    }
  };

  const columns = [
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Фамилия",
      dataIndex: "surname",
      key: "surname",
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Тип",
      dataIndex: "type",
      key: "type",
      render: (type: CourierType) => type,
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (status: CourierStatus) =>
        status === CourierStatus.ACTIVE ? (
          <CheckOutlined style={{ color: "green" }} />
        ) : (
          <CloseOutlined style={{ color: "red" }} />
        ),
    },
    {
      title: "Действия",
      key: "actions",
      render: (text: string, record: Courier) => (
        <Space size="middle">
          <Popconfirm
            title="Вы уверены, что хотите удалить этого курьера?"
            onConfirm={() => handleDelete(record._id)}
            okText="Да"
            cancelText="Нет"
          >
            <Button danger loading={courierLoading}>
              Удалить
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const formatPhoneNumber = (value: string) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };
  if (loading) return <Spin tip="Загрузка курьеров..." />;

  return (
    <>
      <Table
        dataSource={couriers}
        columns={columns}
        rowKey="_id"
        pagination={false}
        bordered
        title={() => (
          <Flex justify="space-between">
            Курьеры
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setIsModalVisible(true)}
            >
              Новый
            </Button>
          </Flex>
        )}
        style={{ width: "80%", marginTop: 50 }}
      />

      <Modal
        title="Добавить курьера"
        visible={isModalVisible}
        onOk={handleAddCourier}
        onCancel={() => setIsModalVisible(false)}
        confirmLoading={courierLoading}
        okText="Добавить"
        cancelText="Отмена"
      >
        <Form layout="vertical">
          <Form.Item label="Телефон" required>
            <Input
              addonBefore="+7"
              placeholder="Введите номер курьера"
              value={phone}
              onChange={(e) => {
                const formattedPhoneNumber = formatPhoneNumber(e.target.value);
                setPhone(formattedPhoneNumber);
              }}
              disabled={loading}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
