import React, { useEffect, useState } from "react";
import { Chapter } from "../../types/chapter.type";
import useGetRequest from "../../hooks/useGetRequest";
import { useAuth } from "../../contexts/AuthContext/auth.context";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Popconfirm,
  Typography,
  Spin,
  Row,
  Col,
} from "antd";
import { PlusCircleOutlined, DownOutlined } from "@ant-design/icons";
import $api from "../../api/api";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const { Title } = Typography;

export default function Chapters() {
  const { authState } = useAuth();
  const [chapters, setChapters] = useState<Chapter[]>([]);
  const { data, loading, refresh } = useGetRequest(
    `/chapter/my?userType=${authState?.userType}`
  );
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [selectedChapter, setSelectedChapter] = useState<Chapter | null>(null);
  const [isReordering, setIsReordering] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      setChapters(data.chapters);
    }
  }, [data]);

  const handleFormSubmit = async (values: Partial<Chapter>) => {
    setConfirmLoading(true);
    try {
      if (selectedChapter) {
        await $api.put(
          `/chapter/${selectedChapter._id}?userType=${authState?.userType}`,
          values
        );
        message.success("Раздел успешно обновлен.");
      } else {
        await $api.post(`/chapter?userType=${authState?.userType}`, values);
        message.success("Раздел успешно добавлен.");
      }
      refresh();
      setIsModalVisible(false);
      form.resetFields();
      setConfirmLoading(false);
    } catch (error: any) {
      console.error("Error adding/updating chapter:", error.response.data);
      message.error(error.response.data.error);
      setConfirmLoading(false);
    }
  };

  const handleDeleteChapter = async (chapter: Chapter) => {
    try {
      await $api.delete(
        `/chapter/${chapter._id}?userType=${authState?.userType}`
      );
      refresh();
      message.success("Раздел успешно удален.");
    } catch (error: any) {
      message.error(error.response.data.error);
    }
  };

  const showAddModal = () => {
    setSelectedChapter(null);
    setIsModalVisible(true);
    form.resetFields();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedChapter(null);
    form.resetFields();
  };

  const handleEdit = (chapter: Chapter) => {
    if (chapter) {
      form.setFieldsValue(chapter);
      setSelectedChapter(chapter);
      setIsModalVisible(true);
    }
  };

  const onDragEnd = async (result: any) => {
    const { source, destination } = result;

    if (!destination || source.index === destination.index) return;

    setIsReordering(true);

    const reorderedChapters = Array.from(chapters);
    const [removed] = reorderedChapters.splice(source.index, 1);
    reorderedChapters.splice(destination.index, 0, removed);

    setChapters(reorderedChapters);

    const positions = reorderedChapters.map((chapter, index) => ({
      _id: chapter._id,
      position: index,
    }));

    try {
      await $api.post(`/chapter/positions?userType=${authState?.userType}`, {
        positions,
      });
      refresh();
      message.success("Порядок разделов успешно обновлен.");
    } catch (error: any) {
      message.error("Ошибка при обновлении порядка разделов.");
      console.error("Error updating positions:", error.response?.data);
    } finally {
      setIsReordering(false);
    }
  };

  return (
    <div style={{ padding: 16 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 24,
        }}
      >
        <Title level={2}>Разделы</Title>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={showAddModal}
        >
          Добавить
        </Button>
      </div>

      {(loading || isReordering) && (
        <Spin
          size="large"
          tip="Загрузка разделов..."
          style={{ margin: "20px auto", display: "block" }}
        />
      )}

      {!loading && !isReordering && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-chapters" type="group">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ padding: 8 }}
              >
                <Row style={{ marginBottom: 16 }}>
                  <Col span={12}>
                    <strong>Название</strong>
                  </Col>
                </Row>
                {chapters.map((chapter, index) => (
                  <Draggable
                    key={chapter._id}
                    draggableId={chapter._id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          display: "flex",
                          padding: "8px",
                          marginBottom: "8px",
                          backgroundColor: "#fff",
                          borderRadius: "4px",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ flex: 2 }}>{chapter.name}</div>
                        <div style={{ flex: 2 }}>
                          <Dropdown
                            overlay={
                              <Menu>
                                <Menu.Item onClick={() => handleEdit(chapter)}>
                                  Изменить
                                </Menu.Item>
                                <Menu.Item>
                                  <Popconfirm
                                    title="Вы уверены, что хотите удалить раздел? Убедитесь, что раздел не имеет товаров."
                                    onConfirm={() =>
                                      handleDeleteChapter(chapter)
                                    }
                                    okText="Да"
                                    cancelText="Нет"
                                  >
                                    <Button type="link">Удалить</Button>
                                  </Popconfirm>
                                </Menu.Item>
                              </Menu>
                            }
                          >
                            <Button type="link" icon={<DownOutlined />}>
                              Действия
                            </Button>
                          </Dropdown>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      <Modal
        title={selectedChapter ? "Изменить раздел" : "Создать раздел"}
        visible={isModalVisible}
        onOk={() => form.submit()}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
      >
        <Form form={form} onFinish={handleFormSubmit} layout="vertical">
          <Form.Item
            name="name"
            label="Название"
            rules={[
              { required: true, message: "Пожалуйста, введите название!" },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
